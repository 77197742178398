import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <p className="footer__shop-info__about">
          <font _mstmutation="1">
            <Link to="/" _mstmutation="1" onClick={toTop}>
              HOME
            </Link>
            <Link to="/contact" _mstmutation="1" onClick={toTop}>
              お問い合わせ
            </Link>
            <Link to="/privacy" _mstmutation="1" onClick={toTop}>
              プライバシーポリシー
            </Link>
            <Link to="/about" _mstmutation="1" onClick={toTop}>
              このサイトについて
            </Link>
            <Link to="/term" _mstmutation="1" onClick={toTop}>
              利用規約
            </Link>
          </font>
        </p>
        <div className="footer__bot">
          <div className="inner12">
            <div className="sns">
              <ul>
                <li>FOLLOW</li>
                <li>
                  <Link to="/">
                    <img
                      src="https://www.jga.or.jp/image/common/icon_02.svg"
                      alt=""
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://www.jga.or.jp/image/common/icon_03.svg"
                      alt=""
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://www.jga.or.jp/image/common/icon_04.svg"
                      alt=""
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://www.jga.or.jp/image/common/icon_05.svg"
                      alt=""
                    />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="logo">
              <Link to="/">
                <img
                  src="https://www.jga.or.jp/image/common/logo_jga.svg"
                  alt="JGA 日本ゴルフ協会"
                  _mstalt="21898006"
                />
              </Link>
              <p>
                ©Japan Golf Association. All rights reserved.
                <br />
                (公財) 日本ゴルフ協会　無断複製転載を禁ず
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
