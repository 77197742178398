import React, { createContext, useState, useEffect } from "react";
import productData from "../Datas/productData";
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (
    productId,
    quantity,
    size,
    color,
    length,
    head,
    category
  ) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find(
        (item) =>
          item.id === productId &&
          item.size === size &&
          item.color === color &&
          item.length === length &&
          item.head === head &&
          item.category === category
      );

      if (existingItem) {
        return prevItems.map((item) =>
          item.id === productId
            ? { ...item, quantity: item.quantity + quantity }
            : item
        );
      } else {
        const newItem = {
          id: productId,
          quantity,
          ...(size && { size }),
          ...(color && { color }),
          ...(length && { length }),
          ...(head && { head }),
          ...(category && { category }),
        };
        return [...prevItems, newItem];
      }
    });
  };

  const removeFromCart = (productId, size, color, length, head, category) => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => {
        const matchesId = item.id === productId;
        const matchesSize = size ? item.size === size : true;
        const matchesColor = color ? item.color === color : true;
        const matchesLength = length ? item.length === length : true;
        const matchesHead = head ? item.head === head : true;
        const matchesCategory = category ? item.category === category : true;

        return !(
          matchesId &&
          matchesSize &&
          matchesColor &&
          matchesLength &&
          matchesHead &&
          matchesCategory
        );
      })
    );
  };

  const updateQuantity = (
    productId,
    quantity,
    size,
    color,
    length,
    head,
    category
  ) => {
    setCartItems((prevItems) =>
      prevItems.map((item) => {
        const matchesId = item.id === productId;
        const matchesSize = size ? item.size === size : true;
        const matchesColor = color ? item.color === color : true;
        const matchesLength = length ? item.length === length : true;
        const matchesHead = head ? item.head === head : true;
        const matchesCategory = category ? item.category === category : true;

        return matchesId &&
          matchesSize &&
          matchesColor &&
          matchesLength &&
          matchesHead &&
          matchesCategory
          ? { ...item, quantity }
          : item;
      })
    );
  };

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const clearCart = () => {
    setCartItems([]);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        updateQuantity,
        getProductById,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
