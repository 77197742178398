const productData = [
  {
    id: 1,
    name: "【NEWERA】長袖　鹿の子ボタンダウン ポロシャツ ボックスロゴ",
    price: "9900",
    goodNotice: "",
    sizeImg: "/goods/size01.png",
    catagory: [],
    head: [],
    length: [],
    size: ["M", "L", "XL"],
    color: ["オフホワイト", "ブラック"],
    desTitle:
      "ニューエラゴルフの長袖ボタンダウンポロシャツは通気性が高く肌触りのいい鹿の子生地を採用",
    yellowTxt: [
      "メジャーリーグ・ベースボールの全球団に、公式選手用のオンフィールドキャップを供給している「ニューエラ」から、機能性とデザイン性を兼ね備えた、季節の変わり目に使えるボタンダウンの長袖ポロシャツが登場。背面のグラフィックロゴがポイント！",
    ],
    description: [
      "ボタンダウンだからジャケットとの相性もばっちり",
      "見た目はシャツタイプ。着まわしやすさ抜群の長袖鹿の子ポロシャツ",
      "もう定番といってもいいニューエラのゴルフウェアラインから、ボタンダウンの長袖ポロシャツのご紹介です。素材は通気性が良く、肌触りのいい鹿の子生地を使用。",
      "一見シャツのようなデザインですが、上から5個目からは開きみせの飾りボタン。ボタンを全部外す手間もなく、着脱もラクです。",
      "ボタンダウンだから、どんなシーンでも１枚で着られ、少し肌寒くなれば、ベストなどと重ねて着用するのも◎。",
      "カチッとした印象にもなるボタンダウンポロシャツ",
      "ブランドロゴを配したバックプリントがポイントに",
      "割とゆったりとしたシルエットで動きやすいと定評のあるニューエラゴルフラインのウェア。このボタンダウンポロシャツも身体の線を拾わない、着やすさにこだわっている。",
      "襟元は小さいボタンを取り付けたボダンダウン仕様に。襟立ちが良くなるので、ラウンドの行き帰りに着るジャケットとの相性も抜群。",
      "襟元を閉めて着ると襟立ちも一層いい",
      "シーンを選ばないブラックもオススメ",
      "袖・胸ポケット・ボタンにはそれぞれブランドロゴが施され、シンプルながらワンポイントアクセントになっているところもニューエラらしいデザイン。",
      "随所にブランドロゴを配した",
      "色はオフホワイトとブラックの2色展開。冬、重ね着することを考えると、インナーになるポロシャツはシンプルな色で揃えたい。",
      "白でプリントされたロゴがアクセントに",
      "ブラックもオフホワイトのように、白を基調としたブランドロゴを随所にちりばめたデザインになっている。",
      "白を基調にロゴを施した",
      "かっちりとした印象にもなるボタンダウンポロシャツは、シーンを選ばず使い勝手がいい。この機会に1枚もっておくといいかもしれない。",
    ],
    detail: [
      "商品名：【NEWERA】長袖　鹿の子ボタンダウン ポロシャツ ボックスロゴ",
      "カラー：オフホワイト・ブラック",
      "素材：ポリエステル",
      "サイズ：M・L・XL",
      "商品番号：985786",
    ],
    images: [
      "/goods/1001.jpg",
      "/goods/1001a.jpg",
      "/goods/1001b.jpg",
      "/goods/1001c.jpg",
      "/goods/1001d.jpg",
      "/goods/1001e.jpg",
      "/goods/1001f.jpg",
      "/goods/1001g.jpg",
      "/goods/1001h.jpg",
      "/goods/1001i.jpg",
      "/goods/1001j.jpg",
      "/goods/1001k.jpg",
      "/goods/1001l.jpg",
      "/goods/1001m.jpg",
      "/goods/1001n.jpg",
    ],
  },
  {
    id: 2,
    name: "エミリッドバハマ EB-009/EB-007 軟鉄鍛造パター【3パット激減!? 「パンチが入らない」】",
    price: "95150",
    goodNotice:
      "◆受注生産品の為「1～2週間ほど」でのお届けとなります。※パーツ在庫切れの場合は別途納期を連絡させていただきます。",
    sizeImg: "",
    catagory: [],
    head: ["EB-009（ブレードタイプ）", "EB-007（マレットタイプ）"],
    length: ["33インチ", "34インチ", "35インチ"],
    size: [],
    color: [],
    desTitle: "3パット激減!?「パンチが入らない」軟鉄鍛造パター",
    yellowTxt: [
      "大人気のエミリッドバハマから、“パンチが入って大オーバー”という3パットにつながるミスを減らしてくれて、しかも最高にカッコいい軟鉄鍛造パターが登場しました。",
      "パターの素材はステンレスが一般的ですが、このパターの場合はアイアンやウェッジなどに多い軟鉄鍛造。フォージドならではの精悍なフォルムが目を惹きます。",
      "形状は、ブレードタイプとマレットタイプの2種類です。ストロークタイプやお好みで選べます。",
    ],
    description: [
      "EB-009（ブレードタイプ）:",
      "形状は極めてオーソドックスで、ソールのトウヒールにウェートが配されており、シャープさの中にやさしさも併せ持っているのがエミリッドバハマらしさと言えそう。ブラックのヘッドに赤いスコアラインも集中力を高めてくれます。",
      "このパターのトウハング（ヘッドがフリーな状態でパターを水平に横たえたときのフェースの向き）は45度。そのため、フェースを開閉し、弧を描くようなストロークに向いています。",
      "EB-007（マレットタイプ）:",
      "もうひとつのEB-007はクランクネックの軟鉄鍛造マレット。やはりトウヒールにウェートが配されているのも共通点です。こちらもオーソドックスな形状で、構えたときの安心感は極上です。",
      "こちらのトウハングは30度。フェースをあまり開閉させずに、ストレートに近いストロークと相性がいいですが、ヒール寄りにネックがつけられていることで、操作性もきちんと担保されています。",
      "柔らかい打感で3パットが減る!?",
      "さて、このふたつのパターには共通点があります。それが、フェースインサートに特殊アルミ合金が採用されている点です。フェース素材としてアルミはちょっと珍しい印象を受けますが、その特徴は柔らかい打感にあります。",
      "アルミの柔らかい打感に加え、ソールのウェートの効果によりスウィートエリアが広く（＝ミスヒットに強く）、転がりも良いことから、力を入れずに打つことができます。結果、パンチが入らなくなり、ファーストパットが寄せやすくなったり、大オーバーのミスが減ったりといった効果が期待できるのです。",
      "打感が柔らかいから力を抜くことができ、力を抜くことができるからパンチが入らない。そんな好循環が期待できるというわけです。",
      "シャフトは相性抜群の「デラマックス 02P-115M」を採用",
      "この高性能ヘッドに組み合わせたのは、デラマックスの人気パターシャフト「02P-115M」。スチールシャフトよりも低いトルク1.0という超低トルクを実現し、タイミングが合わせやすく、方向性が安定するシャフトです。",
      "パンチの入らないヘッドに「02P-115M」を組み合わせることで、縦の距離感・横の方向性ともに安定。3パットを減らす性能をさらに突き詰めています。",
      "高級感のあるマットクリアーブラック塗装が、EB009/EB007の赤とブラックのヘッドと相性抜群。見た目にも所有感を掻き立てる仕上がりとなっています。",
      "最高のカッコ良さと、パンチが入らない打感の柔らかさを兼ね備えたエミリッドバハマのパター。パターの得意不得意を問わず、おすすめできる1本です。",
    ],
    detail: [
      "商品名：エミリッドバハマ EB007/009 軟鉄鍛造パター【EB-007（マレットタイプ）】",
      "ボディ：軟鉄削り出し",
      "ロフト：4度",
      "ライ角：72度",
      "トウハング：45度",
      "フェース：アルミニウム合金【EB-009（ブレードタイプ）】",
      "ボディ：軟鉄削り出し",
      "ロフト：4度",
      "ライ角：71度",
      "トウハング：30度",
      "フェース：アルミニウム合金【シャフト（デラマックス 02P-115M）】",
      "シャフト重量：116グラム",
      "トルク：1.0",
      "硬さ：M",
      "カラー：マットクリアーブラック,※マット（艶消し）クリアー塗装は、その性質上、キャディーバッグ等での出し入れ時に白い筋状の変色が発生する場合があります。これは「無水アルコール」などで拭いてもらえれば、解消する可能性があります",
      "クラブ長さ：33インチ、34インチ、35インチ",
      "グリップ：STM P-1 Evolve（RED）",
      "右用のみ",
      "商品番号：985719",
      "◆受注生産品につき『配送希望日』のご指定をいただいても、ご指定日にはお届けできません。",
      "◆受注生産品につき、注文後のキャンセルはできません。",
    ],
    images: [
      "/goods/1002.jpg",
      "/goods/1002a.jpg",
      "/goods/1002b.jpg",
      "/goods/1002c.jpg",
      "/goods/1002d.jpg",
      "/goods/1002e.jpg",
      "/goods/1002f.jpg",
      "/goods/1002g.jpg",
      "/goods/1002h.jpg",
      "/goods/1002i.jpg",
      "/goods/1002j.jpg",
      "/goods/1002k.jpg",
    ],
  },
  {
    id: 3,
    name: "【手にすれば笑顔！！手作りの温かみを感じる】アミナコレクション どうぶつ親子の手編みヘッドカバー",
    price: "4400",
    goodNotice: "",
    sizeImg: "",
    catagory: [
      "カエル／DR用",
      "シロクマ／DR用",
      "ネコ／DR用",
      "フクロウ／DR用",
      "パラダイスバード／DR用",
      "カエル／FW・UT用",
      "シロクマ／FW・UT用",
      "ネコ／FW・UT用",
      "フクロウ／FW・UT用",
    ],
    head: [],
    length: [],
    size: [],
    color: [],
    desTitle: "ヒマラヤの麓からやってきた！どうぶつ親子の手編みのヘッドカバー",
    yellowTxt: [
      "アジアを中心とした世界各国の土地に根差したものづくりで雑貨を製造・販売しているアミナコレクションにオーダーした特注品のヘッドカバーは、普段はセーターを編んでいるネパールの女性たちに編んでもらいました。セットで揃えれば笑顔のラウンドになること間違いなし！",
    ],
    description: [
      "カラフルで気分が明るくなる！",
      "遊び心をアイテムにプラスすることで、いつもと違うゴルフが満喫できるかも?!",
      "カエル、シロクマ、ネコ、フクロウの親子たちの愛らしいヘッドカバー",
      "ヒマラヤの麓にあるネパールは寒い冬を越すためにセーターなど編み物が盛んな地域。純朴で笑顔が素敵な編み手のぬくもりが伝わってきてラウンド中に笑顔になれることでしょう。",
      "大型ヘッドのドライバーもちゃんとはまるサイズで、傷からしっかりとクラブを保護してくれます。キャラものでありながらニットなのでかさばらず、スリムなキャディバッグにも収納できる優れものです。",
      "ドライバーは460CCに対応、フェアウェイウッド/ユーティリティ用は180CC対応。画像の左側がドライバー用、右側がフェアウェイウッド/ユーティリティ用です。",
      "●カエルの親子",
      "縁起がいいカエルは親子で色が異なるのもポイントです。クラブとボールを持っているところもチャーミング！",
      "●シロクマの親子",
      "クラブとボールを持ち、とぼけたような表情がなんとも愛らしい",
      "●ネコの親子",
      "長～いしっぽの“ハチワレ”",
      "●フクロウの親子",
      "フクロウはギリシャ神話に登場する知の女神・アテナの従者であり、「森の賢者」と称されるなど知恵の象徴とされ、日本では「不苦労」「福郎」の語呂合わせから、福を呼ぶ象徴とされています。",
      "親子2つ揃えると存在感が増し、人目を惹くこと請け合いです。親子セットでのコーディネートがオススメです！",
      "親子ではないけれど、カラフルな「パラダイスバード」はドライバー用のみあります！",
      "編んでくれた女性たちはゴルフを知らず、「クリケットに似たスポーツ」と説明して作ってもらいました。",
    ],
    detail: [
      "商品名：アミナコレクション　どうぶつ親子の手編みヘッドカバー",
      "サイズ：ドライバー用、フェアウェイウッド＆ユーティリティ用(パラダイスバードはドライバー用のみ)",
      "種類：カエル・シロクマ・ネコ・フクロウ・パラダイスバード",
      "素材：ウール100％",
      "原産国：ネパール",
    ],
    images: [
      "/goods/1003.jpg",
      "/goods/1003a.jpg",
      "/goods/1003b.jpg",
      "/goods/1003c.jpg",
      "/goods/1003d.jpg",
      "/goods/1003e.jpg",
      "/goods/1003f.jpg",
      "/goods/1003g.jpg",
      "/goods/1003h.jpg",
      "/goods/1003i.jpg",
      "/goods/1003j.jpg",
      "/goods/1003k.jpg",
      "/goods/1003l.jpg",
      "/goods/1003m.jpg",
      "/goods/1003n.jpg",
    ],
  },
  {
    id: 4,
    name: "SHOE イタリア製スウェットパーカーベスト【季節の変わり目に重宝！】",
    price: "21450",
    goodNotice:
      "「チョイス道楽」とは……　　　　ゴルフダイジェスト社の雑誌『Choice』が、その道の巨匠とタッグを組み、ゴルフの”道楽”を追求して作った商品です。",
    sizeImg: "/goods/size04.png",
    catagory: [],
    head: [],
    length: [],
    size: ["S", "M", "L"],
    color: ["グレー", "ネイビー"],
    desTitle:
      "ポロシャツ一枚でちょっと寒い、ちょっと味気ないそんなときに大活躍のパーカーベスト",
    yellowTxt: [
      "イタリア・ボローニャから南へ約1時間、フォルリという町で誕生したデザイナーのマルコ・サモレが手がけるブランド「SHOE(シュー)」。",
      "カジュアルなアイテムながらも、自分のファッションの美学を注ぎ込み、「INSPIRED BY EVERYDAY PEOPLE LEAD BY EVERYDAY LIFE」を掲げ、 大人がリラックスしながらも上品に着られるウェアを展開しています。",
    ],
    description: [
      "イタリア・フォルリという町で誕生したブランド「SHOE(シュー)」",
      "ブランド名「SHOE 」のＨ部分のロゴ、＋(プラス)をモチーフにした胸の刺しゅうがアクセントのパーカーベストは、 ポロシャツ一枚では少し肌寒いときや、モックネックスタイルに何かアクセントが欲しい……そんなときに活躍してくれるアイテムです。",
      "カラーはグレーとネイビーの2色展開です。",
      "179センチ72キロのスタッフがMサイズとLサイズを着比べてみました！",
      "ピッタリとした着こなしがお好みな方はワンサイズ下がおススメ。反対に、全体的に余裕を持たせ、オーバーサイズに着こなしたい方はワンサイズ上をお選びください。",
    ],
    detail: [
      "商品名：SHOE(シュー) イタリア製スウェットパーカーベスト",
      "商品番号：971244",
      "カラー：ネイビー・グレー",
      "素材：綿100％",
      "サイズ：S・M・L",
      "生産国：イタリア",
      "※縫製により多少の誤差が生じる場合がございます。また、商品画像は、光の当たり具合やパソコンなどの閲覧環境により、実際の色味と異なって見える場合がございます。予めご了承ください。",
    ],
    images: [
      "/goods/1004.jpg",
      "/goods/1004a.jpg",
      "/goods/1004b.jpg",
      "/goods/1004c.jpg",
      "/goods/1004d.jpg",
      "/goods/1004e.jpg",
      "/goods/1004f.jpg",
      "/goods/1004g.jpg",
      "/goods/1004h.jpg",
      "/goods/1004i.jpg",
      "/goods/1004j.jpg",
      "/goods/1004k.jpg",
      "/goods/1004l.jpg",
      "/goods/1004m.jpg",
      "/goods/1004n.jpg",
    ],
  },
  {
    id: 5,
    name: "【NEWERA】レインウェア ジャケット＆パンツ【レインだってお洒落に着たい！】",
    price: "18700",
    goodNotice: "",
    sizeImg: "/goods/size01.png",
    catagory: [],
    head: [],
    length: [],
    size: ["M", "L", "XL"],
    color: [
      "レインジャケット・カーキ",
      "レインジャケット・ブラック",
      "レインパンツ・カーキ",
      "レインパンツ・ブラック",
    ],
    desTitle: "高耐水圧、高透湿度。機能も充実、かつ着るとかっこいい!!",
    yellowTxt: [
      "アメリカのキャップブランド「ニューエラ」。MLB唯一の公式選手用キャップオフィシャルサプライヤーとして有名ですが、1920年に創業した頃から、当時の流行のファッションに相応しい帽子作りをしています。そんなニューエラだから、レインウェアもかっこいい！",
    ],
    description: [
      "ジャケット、パンツともに",
      "耐水圧10,000mmレベル、透湿度5,000g/㎡ /24hrレベル",
      "耐水圧とは水濡れに対する生地の性能を表し、透湿度とは蒸れにくさを表す数値。",
      "具体的には耐水圧「10,000mm」は、使用生地の上に１cm四方×高さ10,000mm、つまり10メートル!!の水柱を立てても水がしみ出さない（水圧に耐えられる）ということ。",
      "一般的な目安は小雨の水圧が300mm、中雨で2,000mm、大雨で10,000mm程度です。",
      "透湿度「5,000g」というのは、24時間で5リットルの水蒸気を排出する、つまり 1時間で約200ｇの水蒸気を排出してくれるレベルということです。",
      "高耐水圧、高透湿度で、さらに表面強撥水加工の2.5レイヤーラミネート生地を使用。",
      "ジャケットの内側は全面メッシュが施されている",
      "生地で撥水＆蒸れ対策しながらも、裏地のメッシュとベンチレーションでプレー中の汗などによる蒸れをしっかり抑えてくれます。",
      "そして、隠れた!? ポイントは、ゴルフ用のレインには珍しくフードが付いていること。",
      "レインキャップを持っていなくても、フードを被ることで対応できます。",
      "ジャケットとパンツと別々に購入できるのが◎",
      "高機能レインは上下セット売りが多いが、ニューエラゴルフのこちらは、別々で購入できるのがありがたい。ジャケットだけ買って、ウィンドブレーカー代わりに雨の日以外もガンガン着るのもあり。……とレインの機能が充実しながらも、着ると様になるデザイン性もしっかり。",
      "雨の日以外にも着たくなる、そんなレインウェアです。",
    ],
    detail: [
      "商品名：NEWERA GOLF レインウェア ジャケット＆パンツ",
      "商品番号：985784(ジャケット）、985785（パンツ）",
      "サイズ：M、L、XL",
      "カラー：カーキ、ブラック",
      "素材：ポリエステル100%上下別売りです。",
    ],
    images: [
      "/goods/1001.jpg",
      "/goods/1001a.jpg",
      "/goods/1001b.jpg",
      "/goods/1001c.jpg",
      "/goods/1001d.jpg",
      "/goods/1001e.jpg",
      "/goods/1001f.jpg",
      "/goods/1001g.jpg",
      "/goods/1001h.jpg",
      "/goods/1001i.jpg",
      "/goods/1001j.jpg",
      "/goods/1001k.jpg",
      "/goods/1001l.jpg",
      "/goods/1001m.jpg",
      "/goods/1001n.jpg",
      "/goods/1001o.jpg",
      "/goods/1001p.jpg",
      "/goods/1001q.jpg",
      "/goods/1001r.jpg",
      "/goods/1001s.jpg",
      "/goods/1001t.jpg",
      "/goods/1001u.jpg",
      "/goods/1001v.jpg",
      "/goods/1001w.jpg",
      "/goods/1001x.jpg",
    ],
  },
  {
    id: 6,
    name: "ゴルフ規則　2023年１月施行",
    main: "ゴルフ規則書",
    title: [
      "2023年に施行する新しいゴルフ規則です。",
      "世界統一の正式なルールブックとなります。",
    ],
    ship: "880",
    about: [
      "2023年は2019年以来の改訂版！",
      "ゴルファー必携のルールブックです。",
    ],
    change: [
      "■障がいを持つゴルファーのためのゴルフ規則の修正。",
      "■規則3.3b(4) スコアカードにハンディキャップを示すこと、またはスコアの加算はプレーヤーの責任ではなくなった。",
      "■規則4.1a(2) ラウンド中に損傷したクラブの使用、修理、交換ができるようになった。",
      "■規則9.3 救済を受けて自然の力が動かされた球は罰打無しにリプレース。",
      "■後方線上の救済の救済エリアが簡潔化された。",
    ],
    description: [
      "この5つの重要な変更点以外にも多くの規定が改訂されています。例えば ・間違って取り替えた球の罰が一般の罰から1罰打に軽減された(規則6.3)。",
      "・コース上のどこであっても、プレーの線を示すために物を置いてはならない(規則10.2b)。",
      "・再プレーを要求する規則に従わず、再プレーをしなかった場合は誤所からのプレーとはならず、一般の罰を受ける(例えば規則11.2)。",
      "・変えられたライを復元することを要求する規則に従わずにプレーした場合、場所が正しければ誤所からのプレーとはならず、一般の罰を受ける(規則14.2d)。",
      "・球を見つけた後(球の捜索時間3分以内に)、その球を確認するための合理的な時間は1分認められる(規則18.2a)。",
      "他にも重要な変更点があります。詳細は本書でご確認ください。",
    ],
    notice: [
      "●この版は日本ゴルフ協会（JGA）よりゴルフダイジェスト社が受託販売をしています。",
      "●ご購入には本サイト（ゴルフポケット）へのご登録(無料)が必要となります。ゴルフ場・団体等の法人様は、ご登録画面の「お名前」の欄に、法人名（会社名）と担当者名を必ずご記入下さい。",
      "●一度のご注文につき送料を全国一律880円(税込)頂戴いたします。また「カートにいれる」のアイコン上にある記載文「ご購入額 3,980円（税込）以上で送料無料」は適用されませんので予めご了承ください。",
      "本書は他商品とは同時購入は出来ません。同時購入をご希望の場合は、別途ご注文ください。同時購入を確認した場合、注文をキャンセルし再購入のご案内をさせていただく事がございます。",
      "●ご注文日から1週間程度（営業日のみ）には発送いたします。",
      "●決済方法を代金引換払いの場合は、代引手数料として別途330円（税込）を申し受けます。※5,000円（税込）以上の場合、代引き手数料は無料です。",
      "●請求書対応・銀行振込をご希望の場合、下記メールアドレスまでご連絡ください。ご入金確認後1週間程度（営業日のみ）の発送となります。（E-mail：gdhanbai@golf-digest.co.jp）",
      "●領収書について",
      "商品を出荷した際に出荷案内のご案内メールを配信いたします。そのメールに記載されております『マイページURL』よりお客様ご自身で宛名・敬称・但し書きを入力し、ダウンロード・印刷をお願いいたします。",
      "領収書に取引内容(商品名)が必要な場合、また、代金引換をご利用のお客様はお手数ではございますが、弊社までご連絡ください。",
      "【お問い合わせ】 ゴルフダイジェスト社 販売マーケティング部",
      "●TEL：03-3431-3060(平日10：00～12：00/14：00～17：00)",
      "●E-mail：gdhanbai@golf-digest.co.jp",
    ],
    information: [
      "タイトル／ゴルフ規則 2023年1月施行",
      "著者／R＆A・USGA",
      "編集・翻訳／日本ゴルフ協会",
      "発売／ゴルフダイジェスト社",
      "発売日／2022年12月1日",
      "判型／A5判",
      "ページ数／200ページ",
      "定価／1,650円(税込)",
      "ISBNコード／978-4-7728-4211-2　C2075",
      "送料／880円(税込)",
      "【お届けについて】",
      "お届けはご注文時から約1週間程（営業日のみ）となり、天候不良などに伴い現在約1～2週間程かかる場合がございます。",
      "その為、ご注文時に配達希望日を指定頂きましても 配送希望日にお届け出来かねる場合がございますのでご了承ください。",
      "お届けはヤマト運輸の宅配便となります。",
    ],
    price: "1650",
    size: "A5",
    images: ["/book/1001.jpg"],
  },
  {
    id: 7,
    name: "ゴルフ規則のオフィシャルガイド　2023年１月施行",
    main: "2023年ゴルフ規則のオフィシャルガイド",
    title: [
      "2023年の新しいゴルフ規則のオフィシャルガイドです。",
      "各規則の詳説や関連事例の説明、競技運営に関するガイダンスなどが掲載されている競技運営者やレフェリーに必携のゴルフ規則専門書です。",
    ],
    ship: "880",
    about: [
      "2019年以来の改訂となった2023年の最新ルールを",
      "詳細に解説したオフィシャルガイド（旧裁定集）",
      "競技関係者必携です。",
      "R&AとUSGAが編集したゴルフ規則のオフィシャルガイド。言わばゴルフ規則の『参考書』です。",
      "コース上で生じる疑問の多くは「ゴルフ規則」を参照することで解決できますが、このガイドには、様々に起こる複雑なシチュエーションについて該当規則の解釈と裁定におけるオフィシャルな立場からの「詳説」を掲載しています。",
      "ゴルフ場、クラブの競技委員、競技を運営する関係者にとってぜひ常備しておきたい1冊です。",
    ],
    //change一样
    change: [
      "■障がいを持つゴルファーのためのゴルフ規則の修正。",
      "■規則3.3b(4) スコアカードにハンディキャップを示すこと、またはスコアの加算はプレーヤーの責任ではなくなった。",
      "■規則4.1a(2) ラウンド中に損傷したクラブの使用、修理、交換ができるようになった。",
      "■規則9.3 救済を受けて自然の力が動かされた球は罰打無しにリプレース。",
      "■後方線上の救済の救済エリアが簡潔化された。",
    ],
    //des一样
    description: [
      "この5つの重要な変更点以外にも多くの規定が改訂されています。例えば ・間違って取り替えた球の罰が一般の罰から1罰打に軽減された(規則6.3)。",
      "・コース上のどこであっても、プレーの線を示すために物を置いてはならない(規則10.2b)。",
      "・再プレーを要求する規則に従わず、再プレーをしなかった場合は誤所からのプレーとはならず、一般の罰を受ける(例えば規則11.2)。",
      "・変えられたライを復元することを要求する規則に従わずにプレーした場合、場所が正しければ誤所からのプレーとはならず、一般の罰を受ける(規則14.2d)。",
      "・球を見つけた後(球の捜索時間3分以内に)、その球を確認するための合理的な時間は1分認められる(規則18.2a)。",
      "他にも重要な変更点があります。詳細は本書でご確認ください。",
    ],
    //notice有一点不一样
    notice: [
      "●この版は日本ゴルフ協会（JGA）よりゴルフダイジェスト社が受託販売をしています。",
      "●ご購入には本サイト（ゴルフポケット）へのご登録(無料)が必要となります。ゴルフ場・団体等の法人様は、ご登録画面の「お名前」の欄に、法人名（会社名）と担当者名を必ずご記入下さい。",
      "●一度のご注文につき送料を全国一律880円(税込)頂戴いたします。また「カートにいれる」のアイコン上にある記載文「ご購入額 3,980円（税込）以上で送料無料」は適用されませんので予めご了承ください。",
      "本書は他商品とは同時購入は出来ません。同時購入をご希望の場合は、別途ご注文ください。同時購入を確認した場合、注文をキャンセルし再購入のご案内をさせていただく事がございます。",
      "●ご注文日から1週間程度（営業日のみ）には発送いたします。",
      "●代引き手数料は無料です。",
      "●請求書対応・銀行振込をご希望の場合、下記メールアドレスまでご連絡ください。ご入金確認後1週間程度（営業日のみ）の発送となります。（E-mail：gdhanbai@golf-digest.co.jp）",
      "●領収書について",
      "商品を出荷した際に出荷案内のご案内メールを配信いたします。そのメールに記載されております『マイページURL』よりお客様ご自身で宛名・敬称・但し書きを入力し、ダウンロード・印刷をお願いいたします。",
      "領収書に取引内容(商品名)が必要な場合、また、代金引換をご利用のお客様はお手数ではございますが、弊社までご連絡ください。",
      "【お問い合わせ】 ゴルフダイジェスト社 販売マーケティング部",
      "●TEL：03-3431-3060(平日10：00～12：00/14：00～17：00)",
      "●E-mail：gdhanbai@golf-digest.co.jp",
    ],
    information: [
      "タイトル／ゴルフ規則のオフィシャルガイド 2023年1月施行",
      "著者／R＆A・USGA",
      "編集・翻訳／日本ゴルフ協会",
      "発売／ゴルフダイジェスト社",
      "発売日／2023年3月7日",
      "判型／A5判",
      "ページ数／608ページ",
      "定価／5,500円(税込)",
      "ISBNコード／978-4-7728-4212-9",
      "送料／880円(税込)",
      "【お届けについて】",
      "お届けはご注文時から約1週間程（営業日のみ）となり、天候不良などに伴い現在約1～2週間程かかる場合がございます。",
      "その為、ご注文時に配達希望日を指定頂きましても 配送希望日にお届け出来かねる場合がございますのでご了承ください。",
      "お届けはヤマト運輸の宅配便となります。",
    ],
    price: "5500",
    size: "A5",
    images: ["/book/1002.jpg"],
  },
];

export default productData;
