import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import "../css/main.css";
import "../css/book.css";
import productData from "../Datas/productData";

function Shop() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <Header />
      <div className="book__div">
        <div className="book__div__inner">
          <h1>
            Books<span>出版物のご案内</span>
          </h1>
          <h2>2023年ゴルフ規則について</h2>
          <p>
            2023年1月1日施行のゴルフ規則に関する出版物をご案内いたします。
            <br />
            なお、簡略版のゴルフ規則プレーヤーズ版は廃止されました。
          </p>
          {productData
            .filter((e) => e.id >= 6 && e.id <= 7)
            .map((book, index) => (
              <div className="book_goods" key={index}>
                <div className="img">
                  <img src={book.images[0]} alt="" />
                </div>
                <div className="txt6">
                  <div className="txtInner">
                    <h3>{book.main}</h3>
                    {book.title.map((title, index) => (
                      <p key={index}>{title}</p>
                    ))}

                    <p>
                      価格：{book.price}円（税込）
                      <br />
                      サイズ：{book.size}判
                    </p>
                    <Link
                      to={`/bookDetails/${book.id}`}
                      onClick={handleNavigate}
                    >
                      <span>ご購入はこちら</span>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div id="shopify-section-1662364965370b96b8" className="shopify-section">
        <div className="custom-field">
          <div className="title">
            <h2>
              <span>すべての商品</span>
            </h2>
          </div>
        </div>
      </div>
      <div className="mainBox">
        <div className="container-fluid">
          {/* top */}
          <div className="top-container">
            <div className="product-grid">
              {productData
                .filter((e) => e.id >= 1 && e.id <= 5)
                .map((brand) => (
                  <div className="product-category" key={brand.id}>
                    <div className="image-container">
                      <Link
                        to={`/shopDetails/${brand.id}`}
                        onClick={handleNavigate}
                      >
                        <img alt="" src={brand.images[0]} />
                      </Link>

                      <h2>{brand.name}</h2>
                      <div className="pp-pre">
                        <h4 className="red-price">{brand.price}円(税込)</h4>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Shop;
