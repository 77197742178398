import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const { addToCart } = useContext(CartContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("colours");
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedLength, setSelectedLength] = useState(null);
  const [selectedHead, setSelectedHead] = useState(null);
  const [selectedCatagory, setSelectedCatagory] = useState(null);
  const [quantities, setQuantities] = useState(1);

  const handleAddToCart = () => {
    if (product.size.length > 0 && !selectedSize) {
      alert("サイズを選択してください！");
      return;
    }
    if (product.color.length > 0 && !selectedColor) {
      alert("カラーを選択してください！");
      return;
    }
    if (product.length.length > 0 && !selectedLength) {
      alert("長さを選択してください！");
      return;
    }
    if (product.head.length > 0 && !selectedHead) {
      alert("ヘッドを選択してください！");
      return;
    }
    if (product.catagory.length > 0 && !selectedCatagory) {
      alert("カテゴリーを選択してください！");
      return;
    }

    const options = {
      size: selectedSize || null,
      color: selectedColor || null,
      length: selectedLength || null,
      head: selectedHead || null,
      category: selectedCatagory || null,
    };

    addToCart(
      product.id,
      quantities,
      options.size,
      options.color,
      options.length,
      options.head,
      options.category
    );
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/cartpage");
  };
  if (!product) {
    return <div>商品が見つかりません</div>;
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="main-container">
      <Header />
      <div className="shopDiv">
        <div className="product-detail">
          <div className="product-images">
            <div className="carousel">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${product.name}`}
                  style={{ display: index === currentIndex ? "block" : "none" }}
                />
              ))}
            </div>
            <div className="carousel-buttons">
              {product.images.map((image, index) => (
                <button key={index} onClick={() => setCurrentIndex(index)}>
                  <img src={image} alt={`${product.name}`} />
                </button>
              ))}
            </div>
          </div>
          <div className="product-info">
            <h3 className="sldo__name">
              <strong style={{ color: "#000" }}>{product.main}</strong>
            </h3>
            <h3 className="sldo__name">{product.name}</h3>
            <div className="prices sale">
              <p className="box__price">
                <span className="price">
                  {product.price}
                  <span className="tax">円(税込)</span>
                </span>
                {product.off && (
                  <span className="box__price--rate">({product.off} Off)</span>
                )}
              </p>
            </div>
            {product.size.length > 0 && (
              <>
                <div className="rio-product-option-title">サイズ</div>
                <div className="sldo__size_div">
                  <select
                    value={selectedSize}
                    onChange={(e) => setSelectedSize(e.target.value)}
                    className={selectedSize ? "selected" : ""}
                  >
                    <option value="">選択してください</option>
                    {product.size.map((size, index) => (
                      <option key={index} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}

            {product.color.length > 0 && (
              <>
                <div className="rio-product-option-title">カラー</div>
                <div className="sldo__size_div">
                  <select
                    value={selectedColor}
                    onChange={(e) => setSelectedColor(e.target.value)}
                    className={selectedColor ? "selected" : ""}
                  >
                    <option value="">色を選択してください</option>
                    {product.color.map((color, index) => (
                      <option key={index} value={color}>
                        {color}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}
            {product.catagory.length > 0 && (
              <>
                <div className="rio-product-option-title">カテゴリ</div>
                <div className="sldo__size_div">
                  <select
                    value={selectedCatagory}
                    onChange={(e) => setSelectedCatagory(e.target.value)}
                    className={selectedCatagory ? "selected" : ""}
                  >
                    <option value="">種類を選択してください</option>
                    {product.catagory.map((catagory, index) => (
                      <option key={index} value={catagory}>
                        {catagory}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}
            {product.head.length > 0 && (
              <>
                <div className="rio-product-option-title">頭</div>
                <div className="sldo__size_div">
                  <select
                    value={selectedHead}
                    onChange={(e) => setSelectedHead(e.target.value)}
                    className={selectedHead ? "selected" : ""}
                  >
                    <option value="">頭を選択してください</option>
                    {product.head.map((head, index) => (
                      <option key={index} value={head}>
                        {head}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}
            {product.length.length > 0 && (
              <>
                <div className="rio-product-option-title">長さ</div>
                <div className="sldo__size_div">
                  <select
                    value={selectedLength}
                    onChange={(e) => setSelectedLength(e.target.value)}
                    className={selectedLength ? "selected" : ""}
                  >
                    <option value="">長さを選択してください</option>
                    {product.length.map((length, index) => (
                      <option key={index} value={length}>
                        {length}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}
            <span>
              数量：&nbsp;
              <input
                type="number"
                value={quantities}
                min="1"
                onChange={(e) =>
                  setQuantities(Math.max(1, parseInt(e.target.value, 10)))
                }
                className="quantity-input"
              />
            </span>
            <br />
            <div className="btnDiv">
              <button className="add-to-cart" onClick={handleAddToCart}>
                カートに追加
              </button>
            </div>
            <div className="item-des-color">
              <div className="tab-set__tab-group">
                <div className="tab-set__tab-window">
                  <div
                    className={`tab-set__tab ${
                      activeTab === "description" ? "is-active" : ""
                    }`}
                    onClick={() => handleTabChange("description")}
                  >
                    サイズ・素材
                  </div>
                  <div
                    className={`tab-set__tab ${
                      activeTab === "colours" ? "is-active" : ""
                    }`}
                    onClick={() => handleTabChange("colours")}
                  >
                    アイテム説明
                  </div>
                </div>
              </div>
              <div className="tab-set__body-group">
                <div
                  className={`tab-set__body ${
                    activeTab === "description" ? "is-active" : ""
                  }`}
                >
                  <div className="box-simple">
                    <h4>詳細情報</h4>
                    <ul>
                      {product.detail.map((detail, index) => (
                        <li key={index}>{detail}</li>
                      ))}
                    </ul>
                  </div>
                  {product.sizeImg && <img alt="" src={product.sizeImg} />}
                </div>
                <div
                  className={`colors-item ${
                    activeTab === "colours" ? "is-active" : ""
                  }`}
                >
                  <ul className="describe__ul">
                    {product.description.map((description, index) => (
                      <li key={index}>{description}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;
