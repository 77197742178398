import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import "../css/bookDetail.css";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

const BookDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const { addToCart } = useContext(CartContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [quantities, setQuantities] = useState(1);

  const handleAddToCart = () => {
    addToCart(product.id, quantities, null, null, null, null, null);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/cartpage");
  };
  if (!product) {
    return <div>商品が見つかりません</div>;
  }

  // const handleTabChange = (tab) => {
  //   setActiveTab(tab);
  // };

  return (
    <div className="main-container">
      <Header />
      <div className="shopDiv">
        <div className="product-detail">
          <div className="product-images">
            <div className="carousel">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${product.name}`}
                  style={{ display: index === currentIndex ? "block" : "none" }}
                />
              ))}
            </div>
            <div className="carousel-buttons" style={{ background: "none" }}>
              {product.images.map((image, index) => (
                <button key={index} onClick={() => setCurrentIndex(index)}>
                  <img src={image} alt={`${product.name}`} />
                </button>
              ))}
            </div>
          </div>
          <div className="product-info">
            <h3 className="sldo__name">
              <strong style={{ color: "#000" }}>{product.name}</strong>
            </h3>
            <div className="prices sale">
              <p className="box__price">
                <span className="price">
                  {product.price}
                  <span className="tax"> 円(税込)</span>
                </span>
              </p>
            </div>
            <p style={{ background: "#F0F8FF", padding: "15px" }}>
              ◆送料：全国一律{product.ship}円（税込）
            </p>
            <span
              style={{
                color: "red",
                fontSize: "18px",
                lineHeight: "2.2",
                fontWeight: "400",
              }}
            >
              <strong>
                本書は他の商品とは同時購入は出来ません。他商品もご購入の場合は、ご注文完了後に改めてご注文ください。
                <br />
                <u>※「ゴルフ規則　2023年１月施行」と同時購入はできません。</u>
              </strong>
            </span>
            <br />
            <span>
              数量：&nbsp;
              <input
                type="number"
                value={quantities}
                min="1"
                onChange={(e) =>
                  setQuantities(Math.max(1, parseInt(e.target.value, 10)))
                }
                className="quantity-input"
              />
            </span>
            <br />

            <div className="btnDiv">
              <button className="add-to-cart" onClick={handleAddToCart}>
                カートにいれる
              </button>
            </div>
          </div>
        </div>
        <div className="book__details">
          <div id="b-all">
            <div className="book_about">
              <p className="b-headline">内容紹介</p>
              <div className="b-contents">
                {product.about.map((about, index) => (
                  <p key={index}>{about}</p>
                ))}
              </div>
            </div>
            <div className="book_about">
              <p className="b-headline">
                2023改訂規則の主要な変更点（R&Aリリースより）
              </p>
              <div className="b-contents">
                {product.change.map((change, index) => (
                  <p key={index} style={{ marginBottom: "0" }}>
                    {change}
                  </p>
                ))}
              </div>
            </div>
            <hr />
            <div className="book_about">
              <div className="b-contents">
                {product.description.map((description, index) => (
                  <p
                    key={index}
                    style={{
                      marginBottom: "0",
                      fontSize: "15px",
                      fontWeight: "400",
                      lineHeight: "1.5",
                    }}
                  >
                    {description}
                  </p>
                ))}
              </div>
            </div>
            <div className="book_about">
              <p className="b-headline">注文のご注意</p>
              <div className="b-contents">
                {product.notice.map((notice, index) => (
                  <p
                    key={index}
                    style={{
                      marginBottom: "0",
                      fontSize: "15px",
                      fontWeight: "400",
                      lineHeight: "1.5",
                    }}
                  >
                    {notice}
                  </p>
                ))}
              </div>
            </div>
            <div className="book_about">
              <p className="b-headline">詳細情報</p>
              <div className="b-contents">
                {product.information.map((information, index) => (
                  <p
                    key={index}
                    style={{
                      marginBottom: "0",
                      fontSize: "15px",
                      fontWeight: "400",
                      lineHeight: "1.5",
                    }}
                  >
                    {information}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BookDetail;
