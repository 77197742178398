import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";
import { Link } from "react-router-dom";

function Contact() {
  const [name, setName] = useState({ lastName: "", firstName: "" });
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const contactSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!name.lastName || !name.firstName) newErrors.name = "Enter a full name";
    if (!mail) newErrors.mail = "Enter an email";
    if (!phone) newErrors.phone = "Enter a phone number";
    if (!message) newErrors.message = "Enter a message";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    } else {
      alert("送信が成功しました！");
      setName({ lastName: "", firstName: "" });
      setMail("");
      setPhone("");
      setMessage("");
    }
  };

  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div id="main">
            <h1>
              Contact<span>お問い合わせ</span>
            </h1>
          </div>
          <p>
            ジュニア会員に関するお問い合わせは、下記の各窓口へお願いします。
            <br />
            「JGAジュニア会員サービス」サイトでは、よくあるお問い合わせをQ&amp;A形式でご紹介しています。
            <br />
            お問い合わせの前に、まずは「よくある質問」の該当項目をご一読ください。
          </p>
          <section id="contact">
            <h2>
              下記のような質問がある時には、日本ゴルフ協会(JGA)
              へお問い合わせ下さい
            </h2>
            <ul className="txtList">
              <li>・会員募集要項に関するご質問</li>
              <li>・入会特典の発送状況について</li>
              <li>・J-sysご利用申込みについて</li>
            </ul>
            <div className="box">
              <p>
                公益財団法人 日本ゴルフ協会
                <br />
                受付時間：09:30-17:30
                <br />
                <span>TEL：03-6275-2644　FAX：03-6275-2642</span>
                <br />
                〒104-0032　東京都中央区八丁堀2-24-2　八丁堀第一生命ビルディング4階
              </p>
            </div>
          </section>

          <section id="contact">
            <h2>
              下記のような質問がある時には、JGAヘルプデスク へお問い合わせ下さい
            </h2>
            <h3>「JGAジュニア会員サービスサイト」の操作方法について</h3>
            <ul className="txtList">
              <li>・新規会員登録、更新手続き</li>
              <li>・決済に関するご質問</li>
              <li>・会員登録内容の確認・変更について</li>
            </ul>
            <div className="box">
              <p>JGAヘルプデスク</p>
            </div>
          </section>

          <section>
            <h2>
              下記のような質問がある時には、各地区ゴルフ連盟へお問い合わせ下さい
            </h2>
            <ul className="txtList">
              <li>・ジュニアゴルフスクールについて（開催概要、申込みなど）</li>
              <li>・地区ゴルフ連盟主催競技についてなど</li>
            </ul>
            <div className="tableOver">
              <table className="tableList">
                <tbody>
                  <tr>
                    <th width="19%">団体名</th>
                    <th width="47%">住所</th>
                    <th width="17%">電話番号</th>
                    <th width="17%">FAX</th>
                  </tr>
                  <tr>
                    <td nowrap="">
                      <a href="http://www.hga.gr.jp/">北海道ゴルフ連盟</a>
                    </td>
                    <td>〒064-0804　札幌市中央区南四条西3丁目　北星ビル3階</td>
                    <td nowrap="">
                      <span>電話番号：</span>011-221-4564
                    </td>
                    <td nowrap="">
                      <span>FAX：</span>011-221-4519
                    </td>
                  </tr>
                  <tr>
                    <td nowrap="">
                      <a href="http://www.tga.gr.jp/">東北ゴルフ連盟</a>
                    </td>
                    <td>〒980-0811　仙台市青葉区一番町1-1-31 山口ビル5階</td>
                    <td nowrap="">
                      <span>電話番号：</span>022-225-5236
                    </td>
                    <td nowrap="">
                      <span>FAX：</span>022-268-9597
                    </td>
                  </tr>
                  <tr>
                    <td nowrap="">
                      <a href="http://www.kga.gr.jp/">
                        一般社団法人
                        <br />
                        関東ゴルフ連盟
                      </a>
                    </td>
                    <td>〒104-0061　東京都中央区銀座8-18-11　銀座SCビル4階</td>
                    <td nowrap="">
                      <span>電話番号：</span>03-6278-0005
                    </td>
                    <td nowrap="">
                      <span>FAX：</span>03-6278-0008
                    </td>
                  </tr>
                  <tr>
                    <td nowrap="">
                      <a href="http://www.cga.jp/">中部ゴルフ連盟</a>
                    </td>
                    <td>
                      〒450-0002　名古屋市中村区名駅4-26-25　メイフィス名駅ビル5階502号室
                    </td>
                    <td nowrap="">
                      <span>電話番号：</span>052-586-1345
                    </td>
                    <td nowrap="">
                      <span>FAX：</span>052-561-7972
                    </td>
                  </tr>
                  <tr>
                    <td nowrap="">
                      <a href="http://www.kgu.gr.jp/">
                        一般社団法人
                        <br />
                        関西ゴルフ連盟
                      </a>
                    </td>
                    <td>
                      〒550-0002　大阪府大阪市西区江戸堀1-2-16　山下ビル3階
                    </td>
                    <td nowrap="">
                      <span>電話番号：</span>06-6445-3556
                    </td>
                    <td nowrap="">
                      <span>FAX：</span>06-6445-3558
                    </td>
                  </tr>
                  <tr>
                    <td nowrap="">
                      <a href="http://www.cgu.gr.jp/">
                        一般財団法人
                        <br />
                        中国ゴルフ連盟
                      </a>
                    </td>
                    <td>
                      〒730-0037　広島県広島市中区中町3-11&nbsp;中町センタービル3階304号
                    </td>
                    <td nowrap="">
                      <span>電話番号：</span>082-247-2719
                    </td>
                    <td nowrap="">
                      <span>FAX：</span>082-245-0949
                    </td>
                  </tr>
                  <tr>
                    <td nowrap="">
                      <a href="http://www.sgu.gr.jp">四国ゴルフ連盟</a>
                    </td>
                    <td>〒790-0921　松山市福音寺町55-1</td>
                    <td nowrap="">
                      <span>電話番号：</span>089-990-3260
                    </td>
                    <td nowrap="">
                      <span>FAX：</span>089-990-3261
                    </td>
                  </tr>
                  <tr>
                    <td nowrap="">
                      <a href="http://www.guk.jp/">九州ゴルフ連盟</a>
                    </td>
                    <td>
                      〒812-0011　福岡市博多区博多駅前3丁目6番12号　オヌキ博多駅前ビル2階
                    </td>
                    <td nowrap="">
                      <span>電話番号：</span>092-434-5501
                    </td>
                    <td nowrap="">
                      <span>FAX：</span>092-434-5502
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
