import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";

function About() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div id="main">
            <h1>
              About This Site <span>このサイトについて </span>{" "}
            </h1>
          </div>
          <p style={{ marginBottom: "60px" }}>
            公益財団法人日本ゴルフ協会公式ウェブサイトを快適にご利用いただくための情報をご案内いたします。
          </p>
          <div className="inner">
            <h2 className="wp-block-heading">動作環境について</h2>
            <p>当サイトの推奨動作環境は、下記の通りとなっております。</p>

            <h3>◇Windowsをお使いの場合</h3>
            <p>
              OS：Windows XP（SP3）以上
              <br />
              ブラウザ：Internet Explorer 6.0以上
            </p>

            <h3>◇Macintoshをお使いの場合</h3>
            <p>
              OS：Mac OS X以上
              <br />
              ブラウザ：Safari 1.0以上
            </p>

            <p>
              ※上記条件を満たしていない場合、一部の文字が表示されない等、正常なページ閲覧ができない場合があります。
            </p>

            <h2 className="wp-block-heading">モニタサイズについて</h2>
            <p>1024 × 768以上を推奨しております。</p>

            <h2 className="wp-block-heading">プラグインについて</h2>
            <p>当サイトの推奨動作環境は、下記の通りとなっております。</p>

            <h3>◇Adobe Reader（Adobe Reader バージョン6以降）</h3>
            <p>
              PDF形式の書類の閲覧、印刷などのために必要です。
              <br />
              下記のページから無料でダウンロードができます。
              <br />
              http://www.adobe.com/jp/products/acrobat/readstep2.html
            </p>

            <h3>◇Adobe FLASH Player（Adobe FLASH Player バージョン9以降）</h3>
            <p>
              動画やFLASHを使用した一部のコンテンツを閲覧するために必要です。
              <br />
              下記のページから無料でダウンロードができます。
              <br />
              http://www.adobe.com/go/getflashplayer_jp/
            </p>

            <h3>
              ◇Windows Media Player（Windows Media Player バージョン9以降）
            </h3>
            <p>
              一部の動画コンテンツを閲覧するために必要です。
              <br />
              下記のページから無料でダウンロードができます。
              <br />
              http://www.microsoft.com/japan/windows/windowsmedia/
            </p>

            <h2 className="wp-block-heading">Javascriptについて</h2>
            <p>
              当サイトではJavascriptを使用しています。Javascriptを無効にして使用された場合、正常なページ閲覧ができない場合があります。当サイトご利用の際には、Javascriptを有効にしていただくようお願いします。
            </p>

            <h2 className="wp-block-heading">スタイルシート（CSS）について</h2>
            <p>
              当サイトでは，スタイルシート（CSS）を使用してページレイアウトを行っています。スタイルシート（CSS）を無効にして使用された場合、正常なページ閲覧ができない場合があります。当サイトご利用の際には、スタイルシート（CSS）を有効にしていただくようお願いします。
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default About;
