import React, { useContext } from "react";
import { CartContext } from "../Component/CartContext";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/cartpage.css";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const StyledTableContainer = styled(TableContainer)({
  minWidth: 650,
});

const StyledImage = styled("img")({
  width: 112,
  height: 124,
  objectFit: "cover",
});

const StyledTableCell = styled(TableCell)({
  display: "flex",
  alignItems: "center",
  verticalAlign: "middle",
  fontSize: "16px",
  borderRight: "1px dotted #ccc",
});

const CartPage = () => {
  const { cartItems, removeFromCart, updateQuantity } = useContext(CartContext);
  const navigate = useNavigate();

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const toCheckOut = () => {
    if (cartItems.length === 0) {
      alert("チェックアウトに進む前にカートに商品を追加してください。");
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/checkout");
  };

  const calculateTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      const product = getProductById(item.id);
      if (product) {
        const price = parseFloat(product.price);
        total += price * item.quantity || price * 1;
      }
    });
    return total;
  };

  const calculateItemTotal = (itemId, quantity) => {
    const product = getProductById(itemId);
    if (!product) return 0;
    const price = parseFloat(product.price);
    return price * quantity || price * 1;
  };

  const handleInputChange = (
    id,
    event,
    size,
    color,
    length,
    head,
    category
  ) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      updateQuantity(
        id,
        newQuantity,
        size || undefined,
        color || undefined,
        length || undefined,
        head || undefined,
        category || undefined
      );
    }
  };

  return (
    <>
      <div className="main-container">
        <Header />
        <div className="cart-page">
          <div className="basket-title">
            <h1>ショッピングカート</h1>
          </div>
          <div className="cart-total">
            <div className="cart-items">
              {cartItems.length === 0 ? (
                <h3 style={{ color: "black", textAlign: "center" }}>
                  カートは空です！
                </h3>
              ) : (
                <StyledTableContainer component={Paper}>
                  <Table aria-label="shopping cart table">
                    <TableHead
                      style={{ backgroundColor: "#DADADA", height: "80px" }}
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ fontSize: "1.2rem", fontWeight: "600" }}
                        >
                          商品情報
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ fontSize: "1.2rem", fontWeight: "600" }}
                        >
                          価格
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: "1.2rem", fontWeight: "600" }}
                        >
                          数量
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ fontSize: "1.2rem", fontWeight: "600" }}
                        >
                          合計
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: "1.2rem", fontWeight: "600" }}
                        >
                          処理
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cartItems.map((item, index) => {
                        const product = getProductById(item.id);
                        return (
                          <TableRow
                            key={index}
                            style={{ borderBottom: "1px dashed #999" }}
                          >
                            <StyledTableCell component="th" scope="row">
                              <StyledImage
                                src={product.images[0]}
                                alt="Product"
                                style={{
                                  paddingRight: "30px",
                                }}
                              />
                              <div
                                style={{
                                  display: "grid",
                                  width: "70%",
                                }}
                              >
                                {product.name}
                                <br />
                                {item.size && (
                                  <div className="isExist">
                                    サイズ:{item.size}
                                  </div>
                                )}
                                {item.color && (
                                  <div className="isExist">
                                    カラー:{item.color}
                                  </div>
                                )}

                                {item.catagory && (
                                  <div className="isExist">
                                    カラー:{item.catagory}
                                  </div>
                                )}
                                {item.head && (
                                  <div className="isExist">
                                    カラー:{item.head}
                                  </div>
                                )}
                                {item.length && (
                                  <div className="isExist">
                                    カラー:{item.length}
                                  </div>
                                )}
                              </div>
                            </StyledTableCell>
                            <TableCell
                              align="left"
                              style={{
                                verticalAlign: "middle",
                                borderRight: "1px dotted #ccc",
                              }}
                            >
                              ￥{product.price}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              <input
                                type="number"
                                value={item.quantity || 1}
                                className="cartInput"
                                onChange={(e) =>
                                  handleInputChange(
                                    item.id,
                                    e,
                                    item.size,
                                    item.color
                                  )
                                }
                                min="0"
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ verticalAlign: "middle", width: "12%" }}
                            >
                              {calculateItemTotal(item.id, item.quantity)}円
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                verticalAlign: "middle",
                                width: "12%",
                                textAlign: "center",
                              }}
                            >
                              <a
                                onClick={() =>
                                  removeFromCart(
                                    item.id,
                                    item.size || undefined,
                                    item.color || undefined,
                                    item.length || undefined,
                                    item.head || undefined,
                                    item.category || undefined
                                  )
                                }
                                className="remove__goods"
                              >
                                削除
                              </a>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              )}
              {cartItems.length > 0 && (
                <div className="cart-summary">
                  <div className="rightSum">
                    <table className="summary-details">
                      <tbody className="sumTbody">
                        <tr className="sumTr">
                          <th className="subtotalTh">请款金额</th>
                          <th
                            className="subtotalTxt"
                            style={{ color: "#eb5757" }}
                          >
                            <span style={{ fontSize: "12px" }}>JPY</span>
                            {""}
                            <span>
                              {calculateTotal() + calculateTotal() * 0.1}
                            </span>
                            <br />
                            <span
                              style={{
                                fontSize: "13px",
                                color: "#000",
                                fontWeight: "200",
                              }}
                            >
                              约 <strong>USD {calculateTotal() * 0.007}</strong>
                            </span>
                            <br />
                            <span
                              style={{
                                fontSize: "12px",
                                color: "#000",
                                fontWeight: "200",
                              }}
                            >
                              实际以日元的金额结账。
                            </span>
                          </th>
                        </tr>
                        <tr
                          className="sumTr"
                          style={{
                            borderTop: "1px solid #d8d8d8",
                            marginTop: "4%",
                            marginBottom: "4%",
                          }}
                        ></tr>
                        <tr className="sumTr">
                          <th className="subtotalThBot">商品总价</th>
                          <th className="subtotalTxtBot">
                            JPY {calculateTotal()}
                          </th>
                        </tr>
                        <tr className="sumTr">
                          <th className="subtotalThBot">日本国内运费</th>
                          <th className="subtotalTxtBot">JPY 0</th>
                        </tr>
                        <tr className="sumTr">
                          <th className="subtotalThBot">代购服务费</th>
                          <th className="subtotalTxtBot">
                            JPY {calculateTotal() * 0.1}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <button className="checkout-button" onClick={toCheckOut}>
                        ご購入手続きへ
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CartPage;
