import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import productData from "../Datas/productData";
import { Link } from "react-router-dom";

function Main() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="twoDiv">
          <img
            alt=""
            src="https://www.jga.or.jp/data/wp-content/uploads/2024/04/photo_large_22374-1.jpg"
          />
          <div className="txt">
            <span>ナショナルチームニュース | 2024.07.26</span>
            <strong>
              国内外プロツアーで躍進するJGAナショナルチーム出身選手
            </strong>
          </div>
        </div>

        <section id="openBnr">
          <div className="inner">
            <div className="col3">
              <div className="col so">
                <div className="logo" style={{ height: "205px" }}>
                  <img
                    decoding="async"
                    src="https://www.jga.or.jp/image/index/img_opeBnr_so_01.svg"
                    alt=""
                  />
                </div>
                <div className="btnArea" style={{ height: "333px" }}>
                  <h2>第34回日本シニアオープンゴルフ選手権</h2>
                  <p style={{ height: "57px" }}>
                    <span>2024.9.12〜9.15</span>
                    <font _mstmutation="1">
                      千葉カントリークラブ 川間コース ｜ 千葉県
                    </font>
                  </p>
                  <ul>
                    <li>
                      <Link to="/">特設サイト</Link>
                    </li>
                    <li>
                      <Link to="/">スコア速報</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col wo">
                <div className="logo" style={{ height: "205px" }}>
                  <img
                    decoding="async"
                    src="https://www.jga.or.jp/image/index/img_opeBnr_wo_01.svg"
                    alt=""
                  />
                </div>
                <div className="btnArea" style={{ height: "333px" }}>
                  <h2>第57回日本女子オープンゴルフ選手権</h2>
                  <p style={{ height: "57px" }}>
                    <span>2024.9.26～9.29 </span>
                    <font _mstmutation="1">
                      大利根カントリークラブ 西コース ｜ 茨城県
                    </font>
                  </p>
                  <ul>
                    <li>
                      <Link to="/">特設サイト</Link>
                    </li>
                    <li>
                      <Link to="/">スコア速報</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col jo">
                <div className="logo" style={{ height: "205px" }}>
                  <img
                    decoding="async"
                    src="https://www.jga.or.jp/image/index/img_opeBnr_jo_01.svg"
                    alt=""
                  />
                </div>
                <div className="btnArea" style={{ height: "333px" }}>
                  <h2>第89回日本オープンゴルフ選手権</h2>
                  <p style={{ height: "57px" }}>
                    <span>2024.10.10～10.13</span>
                    <font _mstmutation="1">東京ゴルフ俱楽部 ｜ 埼玉県</font>
                  </p>
                  <ul>
                    <li>
                      <Link to="/">特設サイト</Link>
                    </li>
                    <li>
                      <Link to="" className="noLink">
                        スコア速報
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="news">
          <div className="inner">
            <h2>
              <strong>NEWS</strong>
              <span>ニュース</span>
            </h2>
            <div className="newsList">
              <div className="col">
                <Link to="/">
                  <div className="img">
                    <img
                      alt=""
                      src="	https://www.jga.or.jp/data/wp-content/uploads/2024/04/photo_large_22374-1.jpg"
                    />
                  </div>
                  <div className="txt1">
                    <time>2024.09.20</time>
                    <span>ニュース</span>
                    <h3>
                      日本スポーツマスターズ2024長崎大会ゴルフ競技が終了。男子団体は茨城県、女子団体は大阪府が優勝
                    </h3>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link to="/">
                  <div className="img">
                    <img src="https://www.jga.or.jp/image/noImage.png" alt="" />
                  </div>
                  <div className="txt1">
                    <time>2024.09.20</time>
                    <span>JGAニュース</span>
                    <h3>
                      スポーツ庁長官杯第8回ゴルフフェスタ全国大会2024　成績発表
                    </h3>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link to="/">
                  <div className="img">
                    <img
                      alt=""
                      src="https://www.jga.or.jp/data/wp-content/uploads/2024/09/IMG_6663%E6%9C%A8%E6%9D%91%E6%95%8F%E6%96%87%E5%9D%82%E6%9D%B1%E5%B8%82%E9%95%B7%E8%A1%A8%E6%95%AC%E8%A8%AA%E5%95%8F%E2%91%A0-scaled.jpg"
                    />
                  </div>
                  <div className="txt1">
                    <time>2024.09.19</time>
                    <span>ニュース</span>
                    <h3>
                      山中博史専務執行役と中村光伸㈱大利根カントリー倶楽部代表取締役社長が木村敏文坂東市長を表敬訪問
                    </h3>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link to="/">
                  <div className="img">
                    <img
                      alt=""
                      src="	https://www.jga.or.jp/data/wp-content/uploads/2024/04/photo_large_22374-1.jpg"
                    />
                  </div>
                  <div className="txt1">
                    <time>2024.09.17</time>
                    <span>JGAニュース</span>
                    <h3>公益財団法人日本ゴルフ協会 職員募集のお知らせ</h3>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link to="/">
                  <div className="img">
                    <img
                      width="2560"
                      height="1232"
                      src="	https://www.jga.or.jp/data/wp-content/uploads/2024…age_20240916_161855_979-2048x986.jpg?v=1726492882"
                      className="attachment-full size-full wp-post-image"
                      alt=""
                      decoding="async"
                      srcset="https://www.jga.or.jp/data/wp-content/uploads/2024/09/Image_20240916_161855_979-scaled.jpg?v=1726492882 2560w, https://www.jga.or.jp/data/wp-content/uploads/2024/09/Image_20240916_161855_979-300x144.jpg?v=1726492882 300w, https://www.jga.or.jp/data/wp-content/uploads/2024/09/Image_20240916_161855_979-1200x578.jpg?v=1726492882 1200w, https://www.jga.or.jp/data/wp-content/uploads/2024/09/Image_20240916_161855_979-220x106.jpg?v=1726492882 220w, https://www.jga.or.jp/data/wp-content/uploads/2024/09/Image_20240916_161855_979-768x370.jpg?v=1726492882 768w, https://www.jga.or.jp/data/wp-content/uploads/2024/09/Image_20240916_161855_979-1536x739.jpg?v=1726492882 1536w, https://www.jga.or.jp/data/wp-content/uploads/2024/09/Image_20240916_161855_979-2048x986.jpg?v=1726492882 2048w"
                      sizes="(max-width: 2560px) 100vw, 2560px"
                    />
                  </div>
                  <div className="txt1">
                    <time>2024.09.16</time>
                    <span>ニュース</span>
                    <h3>
                      2024年度（第17回）全国小学生ゴルフ大会が終了。男子の部は-4で園和真、宿利龍アラン、広橋璃人の3人が同位優勝。女子の部は阿部歩望が-5で優勝
                    </h3>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link to="/">
                  <div className="img">
                    <img
                      alt=""
                      src="https://www.jga.or.jp/data/wp-content/uploads/2024/09/%E3%83%91%E3%83%AA%E4%BA%94%E8%BC%AA2024%E3%83%81%E3%83%BC%E3%83%A0%E6%9D%BE%E5%B1%B1%E4%B8%B8%E5%B1%B1%E7%9B%A3%E7%9D%A3-scaled.jpg?v=1725933412"
                    />
                  </div>
                  <div className="txt1">
                    <time>2024.09.10</time>
                    <span>JGAニュース</span>
                    <h3>
                      松山英樹選手からのパリオリンピック2024銅メダル報奨金寄付について
                    </h3>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section id="schedule">
          <div className="inner">
            <h2>
              <strong>SCHEDULE</strong>
              <span>競技スケジュール</span>
            </h2>
            <div id="scheduleList">
              <table>
                <tbody>
                  <tr>
                    <td className="time">
                      <time>9/12～9/15</time>
                    </td>
                    <td className="title">
                      <div className="inner">
                        <div className="logo" style={{ marginLeft: "13px" }}>
                          <img
                            decoding="async"
                            src="https://www.jga.or.jp/jga/jsp/2024/11-0/images/club_logo_list.gif"
                            height="37"
                            alt=""
                          />
                        </div>
                        <Link to="/">
                          2024年度 （第34回）
                          <br className="spOnly" />
                          日本シニアオープンゴルフ選手権競技
                        </Link>
                      </div>
                    </td>
                    <td className="course">
                      <Link to="/" rel="noopener">
                        千葉カントリークラブ
                      </Link>
                      <br />
                      川間コース
                    </td>
                    <td className="area">千葉県</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="time">
                      <time>9/26～9/29</time>
                    </td>
                    <td className="title">
                      <div className="inner">
                        <div className="logo">
                          <img
                            decoding="async"
                            src="	https://www.jga.or.jp/jga/jsp/2024/07-0/images/club_logo_list.gif"
                            height="37"
                            alt=""
                          />
                        </div>
                        <Link to="/">
                          2024年度 （第57回）
                          <br className="spOnly" />
                          日本女子オープンゴルフ選手権競技
                        </Link>
                      </div>
                    </td>
                    <td className="course">
                      <Link to="/" rel="noopener">
                        大利根カントリークラブ
                      </Link>
                      <br />
                      西コース
                    </td>
                    <td className="area">茨城県</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="time">
                      <time>10/10～10/13</time>
                    </td>
                    <td className="title">
                      <div className="inner">
                        <div className="logo" style={{ marginLeft: "-4%" }}>
                          <img
                            decoding="async"
                            src="https://www.jga.or.jp/jga/jsp/2024/08-0/images/club_logo_list.gif"
                            height="37"
                            alt=""
                          />
                        </div>
                        <Link to="/">
                          2024年度 （第89回）
                          <br className="spOnly" />
                          日本オープンゴルフ選手権競技
                        </Link>
                      </div>
                    </td>
                    <td className="course">
                      <Link to="/" rel="noopener">
                        東京ゴルフ俱楽部
                      </Link>
                    </td>
                    <td className="area">埼玉県</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>

        <section id="pickup">
          <div className="inner">
            <h2>
              <strong>PICK UP</strong>
              <span>おすすめ</span>
            </h2>
            <div className="newsList">
              <div className="col">
                <Link to="/">
                  <div className="img">
                    <img
                      alt=""
                      src="https://www.jga.or.jp/data/wp-content/uploads/2024/08/2024%E3%83%91%E3%83%AA%E4%BA%94%E8%BC%AA%E5%A5%B3%E5%AD%90%E5%86%99%E7%9C%9F_%E5%8A%A0%E5%B7%A5%E6%B8%88%E3%81%BF.jpg"
                    />
                  </div>
                  <div className="txt2">
                    <time>2024.08.11</time>
                    <span>JGAニュース</span>
                    <h3>
                      パリオリンピック2024女子ゴルフ競技が閉幕。山下美夢有が4位タイ、笹生優花は54位タイ
                    </h3>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link to="/">
                  <div className="img">
                    <img
                      alt=""
                      src="https://www.jga.or.jp/data/wp-content/uploads/2024/08/%E3%83%91%E3%83%AA%E4%BA%94%E8%BC%AA%E6%9C%80%E7%B5%82%E6%97%A5%E6%9D%BE%E5%B1%B1%E9%8A%85%E3%83%A1%E3%83%80%E3%83%AB%E5%8A%A0%E5%B7%A5%E6%B8%88%E3%81%BF.jpg"
                    />
                  </div>
                  <div className="txt2">
                    <time>2024.08.05</time>
                    <span>JGAニュース</span>
                    <h3>
                      パリオリンピック2024男子ゴルフ競技が閉幕。松山英樹が日本男子ゴルフ界初の銅メダルを獲得。中島啓太は通算+3で49位タイ
                    </h3>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link to="/">
                  <div className="img">
                    <img
                      width="1400"
                      height="933"
                      src="https://www.jga.or.jp/data/wp-content/uploads/2024/05/%E4%BB%A3%E8%A1%A8%E6%94%AF%E6%8F%B4%E8%A8%98%E4%BA%8B%E7%94%A8NT%E9%9B%86%E5%90%88%E5%86%99%E7%9C%9F.jpg?v=1714629067"
                      className="attachment-full size-full wp-post-image"
                      alt=""
                      decoding="async"
                      srcset="https://www.jga.or.jp/data/wp-content/uploads/2024/05/代表支援記事用NT集合写真.jpg?v=1714629067 1400w, https://www.jga.or.jp/data/wp-content/uploads/2024/05/代表支援記事用NT集合写真-300x200.jpg?v=1714629067 300w, https://www.jga.or.jp/data/wp-content/uploads/2024/05/代表支援記事用NT集合写真-945x630.jpg?v=1714629067 945w, https://www.jga.or.jp/data/wp-content/uploads/2024/05/代表支援記事用NT集合写真-195x130.jpg?v=1714629067 195w, https://www.jga.or.jp/data/wp-content/uploads/2024/05/代表支援記事用NT集合写真-768x512.jpg?v=1714629067 768w"
                      sizes="(max-width: 1400px) 100vw, 1400px"
                    />
                  </div>
                  <div className="txt2">
                    <time>2024.07.26</time>
                    <span>JGAニュース</span>
                    <h3>
                      選手の育成・強化、日本代表支援に関わる寄付金のお願い
                    </h3>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link to="/">
                  <div className="img">
                    <img
                      alt=""
                      src="https://www.jga.or.jp/data/wp-content/uploads/2024/04/photo_large_22374-1.jpg"
                    />
                  </div>
                  <div className="txt2">
                    <time>2024.07.26</time>
                    <span>ナショナルチームニュース</span>
                    <h3>
                      国内外プロツアーで躍進するJGAナショナルチーム出身選手
                    </h3>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link to="/">
                  <div className="img">
                    <img
                      width="2560"
                      height="1280"
                      src="/data/wp-content/uploads/2024/06/L_DSC00461-scaled.jpg?v=1717993926"
                      className="attachment-full size-full wp-post-image"
                      alt=""
                      decoding="async"
                      srcset="https://www.jga.or.jp/data/wp-content/uploads/2024/06/L_DSC00461-scaled.jpg?v=1717993926 2560w, https://www.jga.or.jp/data/wp-content/uploads/2024/06/L_DSC00461-300x150.jpg?v=1717993926 300w, https://www.jga.or.jp/data/wp-content/uploads/2024/06/L_DSC00461-1200x600.jpg?v=1717993926 1200w, https://www.jga.or.jp/data/wp-content/uploads/2024/06/L_DSC00461-220x110.jpg?v=1717993926 220w, https://www.jga.or.jp/data/wp-content/uploads/2024/06/L_DSC00461-768x384.jpg?v=1717993926 768w, https://www.jga.or.jp/data/wp-content/uploads/2024/06/L_DSC00461-1536x768.jpg?v=1717993926 1536w, https://www.jga.or.jp/data/wp-content/uploads/2024/06/L_DSC00461-2048x1024.jpg?v=1717993926 2048w"
                      sizes="(max-width: 2560px) 100vw, 2560px"
                    />
                  </div>
                  <div className="txt2">
                    <time>2024.06.10</time>
                    <span>ナショナルチームニュース</span>
                    <h3>
                      ガレス・ジョーンズJGAナショナルチームヘッドコーチがエディ・ジョーンズラグビー日本代表ヘッドコーチを表敬訪問
                    </h3>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link to="/">
                  <div className="img">
                    <img
                      alt=""
                      src="https://www.jga.or.jp/data/wp-content/uploads/2024/06/%E5%A4%A7%E4%BC%9A%E3%83%9C%E3%83%A9%E3%83%B3%E3%83%86%E3%82%A3%E3%82%A2%E5%8A%A0%E5%B7%A5%E6%B8%88%E3%81%BF-945x630.jpg?v=1717491209"
                    />
                  </div>
                  <div className="txt2">
                    <time>2024.06.04</time>
                    <span>JGAニュース</span>
                    <h3>
                      2024年日本オープンゴルフ選手権大会ボランティアを募集中
                    </h3>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section id="info">
          <div className="inner">
            <h2>
              <strong>INFORMATION</strong>
              <span>お知らせ</span>
            </h2>
            <div className="newsList">
              <div className="col">
                <Link to="/">
                  <div className="img">
                    <img
                      alt=""
                      src="https://www.jga.or.jp/data/wp-content/uploads/2024/02/TOP%E3%83%90%E3%83%8A%E3%83%BC11-1200628-e1710849802199.jpg"
                    />
                  </div>
                  <div className="txt3">
                    <time>2024.09.18</time>
                    <span>JGAニュース</span>
                    <h3>
                      日本女子オープン・日本オープン会場でスタンプラリー「ごる印めぐり」を開催
                    </h3>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link to="https://www.jga.or.jp/news/jga_news/2024_0912_1835/">
                  <div className="img">
                    <img
                      alt=""
                      src="https://www.jga.or.jp/data/wp-content/uploads/2024/04/photo_large_22374-1.jpg"
                    />
                  </div>
                  <div className="txt3">
                    <time>2024.09.17</time>
                    <span>JGAニュース</span>
                    <h3>公益財団法人日本ゴルフ協会 職員募集のお知らせ</h3>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link to="https://www.jga.or.jp/news/2024_0913_1328/">
                  <div className="img">
                    <img
                      alt=""
                      src="https://www.jga.or.jp/data/wp-content/uploads/2024/04/photo_large_22374-1.jpg"
                    />
                  </div>
                  <div className="txt3">
                    <time>2024.09.13</time>
                    <span>ニュース</span>
                    <h3>TV映像による証拠の取り扱いについての方針</h3>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link to="https://www.jga.or.jp/news/jga_news/2024_0910_1405/">
                  <div className="img">
                    <img
                      alt=""
                      src="https://www.jga.or.jp/data/wp-content/uploads/2024/09/%E3%83%91%E3%83%AA%E4%BA%94%E8%BC%AA2024%E3%83%81%E3%83%BC%E3%83%A0%E6%9D%BE%E5%B1%B1%E4%B8%B8%E5%B1%B1%E7%9B%A3%E7%9D%A3-scaled.jpg?v=1725933412"
                    />
                  </div>
                  <div className="txt3">
                    <time>2024.09.10</time>
                    <span>JGAニュース</span>
                    <h3>
                      松山英樹選手からのパリオリンピック2024銅メダル報奨金寄付について
                    </h3>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link to="https://www.jga.or.jp/news/championship_news/2024_0905_1111/">
                  <div className="img">
                    <img
                      width="3840"
                      height="1920"
                      src="/data/wp-content/uploads/2024/08/2024-JOP.png"
                      className="attachment-full size-full wp-post-image"
                      alt=""
                      decoding="async"
                      srcset="https://www.jga.or.jp/data/wp-content/uploads/2024/08/2024-JOP.png 3840w, https://www.jga.or.jp/data/wp-content/uploads/2024/08/2024-JOP-300x150.png 300w, https://www.jga.or.jp/data/wp-content/uploads/2024/08/2024-JOP-1200x600.png 1200w, https://www.jga.or.jp/data/wp-content/uploads/2024/08/2024-JOP-220x110.png 220w, https://www.jga.or.jp/data/wp-content/uploads/2024/08/2024-JOP-768x384.png 768w, https://www.jga.or.jp/data/wp-content/uploads/2024/08/2024-JOP-1536x768.png 1536w, https://www.jga.or.jp/data/wp-content/uploads/2024/08/2024-JOP-2048x1024.png 2048w"
                      sizes="(max-width: 3840px) 100vw, 3840px"
                    />
                  </div>
                  <div className="txt3">
                    <time>2024.09.04</time>
                    <span>競技ニュース</span>
                    <h3>
                      2024年度（第89回）日本オープンゴルフ選手権最終予選が終了。2会場合計38名の選手が本選進出を決める
                    </h3>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link to="https://www.jga.or.jp/news/jga_news/2024_0827_1804/">
                  <div className="img">
                    <img
                      alt=""
                      src="https://www.jga.or.jp/data/wp-content/uploads/2024/02/TOP%E3%83%90%E3%83%8A%E3%83%BC11-1200628-e1710849802199.jpg"
                    />
                  </div>
                  <div className="txt3">
                    <time>2024.08.28</time>
                    <span>JGAニュース</span>
                    <h3>
                      JGAゴルフ応援サイトNEWS/野口五郎さんインタビュー（ゴルフと健康部会）/ゴルフ場来場者数速報（8月）更新/五浦庭園CCでの親子ふれあいゴルフ教室開催報告、JLPGA、JGTOの取り組みを紹介
                    </h3>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section id="ranking">
          <div className="inner">
            <h2>
              <strong>RANKING</strong>
              <span>アマチュアランキング</span>
            </h2>
            <div className="list">
              <div className="col">
                <div className="rank1">
                  <div className="img">
                    <img
                      src="	https://www.jga.or.jp/jga/jsp/players/images/photo_39491.jpg"
                      alt="松山 茉生"
                    />
                  </div>
                  <div className="txt4">
                    <p className="rank">
                      <span>
                        Rank.<strong>1</strong>
                      </span>
                    </p>
                    <p className="name">松山 茉生</p>
                    <p className="age">
                      <span>年齢：15</span>
                      <span>居住地：愛知県</span>
                    </p>
                    <p className="point">
                      218.41<span>pt.</span>
                    </p>
                  </div>
                </div>
                <table>
                  <tbody>
                    <tr>
                      <td className="rank">
                        <span>Rank.2</span>
                      </td>
                      <td className="name">山下 勝将</td>
                      <td className="age">
                        <span>年齢：21</span>
                        <span>居住地：大阪府</span>
                      </td>
                      <td className="point">187.49pt.</td>
                    </tr>
                    <tr>
                      <td className="rank">
                        <span>Rank.3</span>
                      </td>
                      <td className="name">武田 紘汰</td>
                      <td className="age">
                        <span>年齢：16</span>
                        <span>居住地：徳島県</span>
                      </td>
                      <td className="point">148.32pt.</td>
                    </tr>
                    <tr>
                      <td className="rank">
                        <span>Rank.4</span>
                      </td>
                      <td className="name">中野 麟太朗</td>
                      <td className="age">
                        <span>年齢：20</span>
                        <span>居住地：東京都</span>
                      </td>
                      <td className="point">140.00pt.</td>
                    </tr>
                    <tr>
                      <td className="rank">
                        <span>Rank.5</span>
                      </td>
                      <td className="name">福住 修</td>
                      <td className="age">
                        <span>年齢：21</span>
                        <span>居住地：神奈川県</span>
                      </td>
                      <td className="point">130.67pt.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col">
                <div className="rank1">
                  <div className="img">
                    <img
                      src="https://www.jga.or.jp/jga/jsp/players/images/photo_39406.jpg"
                      alt="新地 真美夏"
                    />
                  </div>
                  <div className="txt4">
                    <p className="rank">
                      <span>
                        Rank.<strong>1</strong>
                      </span>
                    </p>
                    <p className="name">新地 真美夏</p>
                    <p className="age">
                      <span>年齢：16</span>
                      <span>居住地：神奈川県</span>
                    </p>
                    <p className="point">
                      305.50<span>pt.</span>
                    </p>
                  </div>
                </div>
                <table>
                  <tbody>
                    <tr>
                      <td className="rank">
                        <span>Rank.2</span>
                      </td>
                      <td className="name">藤本 愛菜</td>
                      <td className="age">
                        <span>年齢：17</span>
                        <span>居住地：福岡県</span>
                      </td>
                      <td className="point">193.83pt.</td>
                    </tr>
                    <tr>
                      <td className="rank">
                        <span>Rank.3</span>
                      </td>
                      <td className="name">鳥居 さくら</td>
                      <td className="age">
                        <span>年齢：17</span>
                        <span>居住地：兵庫県</span>
                      </td>
                      <td className="point">180.09pt.</td>
                    </tr>
                    <tr>
                      <td className="rank">
                        <span>Rank.4</span>
                      </td>
                      <td className="name">中村 心</td>
                      <td className="age">
                        <span>年齢：18</span>
                        <span>居住地：大阪府</span>
                      </td>
                      <td className="point">179.67pt.</td>
                    </tr>
                    <tr>
                      <td className="rank">
                        <span>Rank.5</span>
                      </td>
                      <td className="name">荒木 優奈</td>
                      <td className="age">
                        <span>年齢：19</span>
                        <span>居住地：宮崎県</span>
                      </td>
                      <td className="point">143.25pt.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <section id="handy-jr">
          <div className="inner">
            <div id="handycap">
              <h2>
                <strong>HANDICAP</strong>
                <span>ハンディキャップ</span>
              </h2>
              <ul>
                <li>
                  <Link to="/">
                    <div className="img border">
                      <img
                        src="https://www.jga.or.jp/image/index/img_handy_01.jpg"
                        alt=""
                      />
                    </div>
                    <div className="txt5">
                      <h3>ハンディキャップ規則</h3>
                      <time>2024年1月施行</time>
                      <span className="pdfLink">PDFを見る</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <div className="img border">
                      <img
                        src="https://www.jga.or.jp/image/index/img_handy_02.jpg"
                        alt=""
                      />
                    </div>
                    <div className="txt5">
                      <h3>
                        ハンディキャップ規則に関する
                        <br className="pcOnly" />
                        プレーヤーリファレンスガイド
                      </h3>
                      <span className="pdfLink">PDFを見る</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <div className="img">
                      <img
                        src="	https://www.jga.or.jp/image/index/img_handy_03.jpg"
                        alt=""
                      />
                    </div>
                    <div className="txt5">
                      <h3>アンダーハンディキャップコンペ</h3>
                      <span className="winLink">エントリーはこちら</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div id="junior">
              <h2>
                <strong>JUNIOR</strong>
                <span>ジュニア</span>
              </h2>
              <div className="bnr">
                <Link to="/">
                  <div className="img">
                    <img
                      src="	https://www.jga.or.jp/image/index/img_junior_01.jpg"
                      alt=""
                    />
                    <div className="ttl">
                      <h3>
                        <span>JGAジュニア</span>会員募集
                      </h3>
                      <p>MEMBER OF JUNIOR</p>
                    </div>
                  </div>
                  <div className="badge">
                    <p>
                      入会
                      <br />
                      申込みは
                      <br />
                      こちら
                    </p>
                  </div>
                </Link>
              </div>
              <p>
                JGAは、ジュニア育成の活動に力を注いでおります。
                <br className="pcOnly" />
                会員になることで、日本ジュニア選手権に参加できる他、地区連盟主催のジュニア選手権、ジュニアスクールに参加できます。
              </p>
            </div>
          </div>
        </section>

        <section id="sponsors">
          <div className="inner">
            <h2>
              <strong>SPONSORS</strong>
              <span>スポンサー</span>
            </h2>
            <div className="box box1">
              <ul>
                <li>
                  <h3>JGA Official Partner</h3>
                  <Link to="/">
                    <img
                      src="https://www.jga.or.jp/image/index/img_sponsors_01.jpg"
                      alt="NEC"
                    />
                  </Link>
                </li>
                <li>
                  <h3>JGA Official Airline</h3>
                  <Link to="/">
                    <img
                      src="https://www.jga.or.jp/image/index/img_sponsors_02.jpg"
                      alt="JAL"
                    />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="box box2">
              <h3>JGA Sponsor</h3>
              <ul>
                <li>
                  <Link to="/">
                    <img
                      src="https://www.jga.or.jp/image/index/img_sponsors_17.jpg?202406031633"
                      alt="久光製薬"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://www.jga.or.jp/image/index/img_sponsors_03.jpg"
                      alt="江崎グリコ株式会社"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://www.jga.or.jp/image/index/img_sponsors_04.jpg"
                      alt="ヤマト運輸株式会社"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://www.jga.or.jp/image/index/img_sponsors_05.jpg"
                      alt="株式会社関電工"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://www.jga.or.jp/image/index/img_sponsors_06.jpg"
                      alt="旭化成ホームズ株式会社"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="	https://www.jga.or.jp/image/index/img_sponsors_07.jpg"
                      alt="コグニザントジャパン株式会社"
                    />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="box box3">
              <h3>JGA Supporter</h3>
              <ul>
                <li>
                  <Link to="/">
                    <img
                      src="https://www.jga.or.jp/image/index/img_sponsors_09.jpg"
                      alt="DESCENTE"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://www.jga.or.jp/image/index/img_sponsors_10.jpg"
                      alt="SRIXON"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://www.jga.or.jp/image/index/img_sponsors_11.jpg"
                      alt="Footjoy"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://www.jga.or.jp/image/index/img_sponsors_12.jpg"
                      alt="ThreeBond"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://www.jga.or.jp/image/index/img_sponsors_13.jpg"
                      alt="森ビル株式会社"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://www.jga.or.jp/image/index/img_sponsors_16.jpg"
                      alt="あったか家族のお手伝い　日本一"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <div id="bnrArea">
          <div className="inner">
            <ul className="bnrAreaListL">
              <li>
                <Link to="/">
                  <img src="https://www.jga.or.jp/image/common/banner_donation.jpg" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src="https://www.jga.or.jp/image/common/banner_rulebook_2023.jpg" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src="https://www.jga.or.jp/image/common/banner_JapanOpenGolfShop2023.jpg" />
                </Link>
              </li>
            </ul>
            <ul className="bnrAreaList">
              <li className="hcp">
                <img
                  src="https://www.jga.or.jp/image/common/img_bnr_01.jpg"
                  alt="ハンディキャップインデックス査定システム　J-sysとは"
                />
                <Link to="/"></Link>
                <Link to="/"></Link>
                <Link to="/"></Link>
              </li>
              <li>
                <Link to="/">
                  <img
                    src="https://www.jga.or.jp/image/common/img_bnr_02.jpg"
                    alt="JGA個人会員募集"
                  />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img
                    src="https://www.jga.or.jp/image/common/img_bnr_03.jpg"
                    alt="世界アマチュアゴルフランキング"
                  />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img
                    src="https://www.jga.or.jp/image/common/img_bnr_04.jpg"
                    alt="JGA主催オープン競技ロゴダウンロード"
                  />
                </Link>
              </li>
            </ul>
            <ul className="bnrAreaList">
              <li>
                <Link to="/">
                  <img
                    src="https://www.jga.or.jp/image/common/img_bnr_05.jpg"
                    alt="クリーンなスポーツを守るアンチ・ドーピング　JADA"
                  />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img
                    src="	https://www.jga.or.jp/image/common/img_bnr_06.jpg"
                    alt="アスリートの盗撮・写真・動画の悪用、悪質なSNS投稿は卑劣な行動です。"
                  />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img
                    src="https://www.jga.or.jp/image/common/img_bnr_07.jpg"
                    alt="JOCオリンピック選手強化寄付プログラム"
                  />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img
                    src="https://www.jga.or.jp/image/common/img_bnr_08.jpg"
                    alt="日本ゴルフサミット会議"
                  />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img
                    src="https://www.jga.or.jp/image/common/img_bnr_09.jpg"
                    alt="経済構造実態調査"
                  />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
