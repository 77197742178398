import "../css/header.css";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

function Header() {
  const { cartItems } = useContext(CartContext);
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="allDiv">
        <div className="headerDiv">
          <div className="leftImg">
            <img alt="" src="/head-foot/head.svg" />
          </div>
          <div className="rightT">
            <p className="cartItemCount">{cartItems.length}</p>
            <Link to="/cartpage" className="to-add" onClick={toTop}>
              <img
                width="26"
                height="26"
                src="https://img.icons8.com/material-rounded/50/shopping-cart.png"
                alt="shopping-cart"
              />
            </Link>
          </div>
        </div>
        <div className="headerMid">
          <nav className="navigation">
            <ul className=" main-nav">
              <li className="menu-item">
                <Link to="/" className="homeToA" onClick={toTop}>
                  ホーム
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/shop" className="homeToA" onClick={toTop}>
                  店
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/privacy" className="homeToA" onClick={toTop}>
                  プライバシーポリシー
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/contact" onClick={toTop}>
                  お問い合わせ
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Header;
