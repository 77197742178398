import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";
import { Link } from "react-router-dom";

function Privacy() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div id="main">
            <h1>
              Privacy Policy <span>個人情報の取扱について￼ </span>{" "}
            </h1>
          </div>
          <p style={{ marginBottom: "60px" }}>
            当協会は、当協会の個人会員の個人情報（以下「個人会員情報」と称します）、ジュニア会員の個人情報（以下「ジュニア会員情報」と称します）、当該個人会員又はジュニア会員となるための入会申込者の個人情報（以下「入会申込者情報」と称します）、当協会主催の公式ゴルフ競技会（以下「選手権」と称します）にかかる参加申込者の参加申込書にかかる個人情報（以下「競技参加者情報」と称します）、当該参加申込者のうち、当協会が特に依頼した者からの自己紹介（選手プロフィール）にかかる個人情報（以下「選手紹介情報」と称します）、当協会がインターネット上で行うゴルフ規則等の商品販売取引のお客様の個人情報（以下「購入申込者情報」と称します）については、次のとおり取扱います。
          </p>
          <div className="inner">
            <h2>1. 基本方針</h2>
            <ul>
              <li>
                (1)
                当協会は、個人情報保護管理者を置くとともに、個人情報管理規程を定め、その監督及び規律のもとに、個人情報の適切な管理を行います。
              </li>
              <li>
                (2)
                当協会は、個人情報の取得に際しては、利用目的を明示し、当該利用目的にかかる業務に必要な限度において、これを取得し、かつ当該利用目的の範囲内においてのみ、これを利用あるいは第三者提供（以下「提供」と称します）致します。
              </li>
              <li>
                (3)
                当協会は、個人情報を他に提供する可能性がある場合には、法令により除外されている場合を除き、予め、提供を伴う利用目的につき、ご本人（情報主体）の同意を得たうえで、その同意を得た範囲内においてのみ、これを提供致します。
              </li>
              <li>
                (4)
                当協会は、取得した個人情報の利用及び提供については、その利用者（従業者）及び受領者（提供先）に対して、必要かつ適正な監督を及ぼすことと致します。
              </li>
              <li>
                (5)
                当協会は、個人情報保護に関する法令を遵守し、かつ個人情報保護に関する指導（所轄省庁等が示すガイドライン）及び社会規範を尊重します。
              </li>
              <li>
                (6)
                当協会は、その保有する個人情報について、安全な管理を確保するために、当該個人情報への不正アクセス、紛失、破壊、改ざん及び漏えい等に対する適切な防御システムの構築並びに改善に努力致します。
              </li>
              <li>
                (7)
                当協会の個人情報管理規程については、社会情勢の変化、情報管理技術の進化等に応じて、継続的に改善を図ることに致します。
              </li>
            </ul>
          </div>

          <div className="inner">
            <h2>2. 利用目的</h2>
            <table className="tableList">
              <tbody>
                <tr>
                  <th>
                    個人会員
                    <br className="spOnly" />
                    情報
                  </th>
                  <td>
                    会員関係物品（会員証・会報等）の送付、その他、個人会員に対する当協会の個人会員業務に関する通知・照会・勧告・請求・案内・広報等の連絡事務。
                  </td>
                </tr>
                <tr>
                  <th>
                    ジュニア
                    <br className="spOnly" />
                    会員情報
                  </th>
                  <td>
                    ジュニア会員関係物品（ジュニア会員証・会報等）の送付、その他、ジュニア会員に対する当協会のジュニア会員業務に関する通知・照会・勧告・請求・案内・広報等の連絡業務。
                    <br />
                    <br />
                    ジュニア会員を被保険者とするゴルファー保険の加入手続き。これには、保険会社に対し、書面により、ジュニア会員の氏名・生年月日（年令）・性別の情報を提供する事務を含みます。
                    <br />
                    <br />
                    当協会が、その地区連盟・その他のゴルフ関連団体の協力を得て行うジュニアスクールの開催および運営。これには、協力する地区連盟およびゴルフ関連団体に対し、適宜の方法で、参加者の氏名・生年月日（年令）・性別・住所・電話番号・学校名（学年）の情報を提供する事務を含みます。
                  </td>
                </tr>
                <tr>
                  <th>
                    入会申込者
                    <br className="spOnly" />
                    情報
                  </th>
                  <td>
                    入会審査手続き（入会後は、その取得した資格に応じ、個人会員情報あるいはジュニア会員情報の利用目的）。
                  </td>
                </tr>
                <tr>
                  <th>
                    競技参加者
                    <br className="spOnly" />
                    情報
                  </th>
                  <td>
                    選手権参加資格の審査。
                    <br />
                    <br />
                    選手権の開催及び運営。これには、㈰参加申込者に対して競技関係資料（組合せ表等）を送付する事務、㈪選手権の開催に際し、競技参加者情報のうち、参加申込者の氏名、生年月日、プロ・アマの別、並びに所属（所属クラブ、プロ選手の場合の所属企業名、学生の場合の学校名及び学年）と、選手権の競技結果とを、選手権関係者（報道関係者を含む）に対して、適宜の方法により公表（第三者提供）する事務を含みます。
                    <br />
                    <br />
                    競技参加者情報と、選手権における競技結果の記録の将来にわたる保存、並びに将来において、必要に応じ、当協会が相当と認める者に対する上記公表事項の適宜の方法による公表。
                  </td>
                </tr>
                <tr>
                  <th>
                    選手紹介
                    <br className="spOnly" />
                    情報
                  </th>
                  <td>
                    入会審査手続き（入会後は、その取得した資格に応じ、個人会員情報あるいはジュニア会員情報の利用目的）。
                  </td>
                </tr>
                <tr>
                  <th>
                    競技参加者
                    <br className="spOnly" />
                    情報
                  </th>
                  <td>
                    選手権参加資格の審査。
                    <br />
                    <br />
                    選手権の開催及び運営。これには、㈰参加申込者に対して競技関係資料（組合せ表等）を送付する事務、㈪選手権の開催に際し、競技参加者情報のうち、参加申込者の氏名、生年月日、プロ・アマの別、並びに所属（所属クラブ、プロ選手の場合の所属企業名、学生の場合の学校名及び学年）と、選手権の競技結果とを、選手権関係者（報道関係者を含む）に対して、適宜の方法により公表（第三者提供）する事務を含みます。
                    <br />
                    <br />
                    競技参加者情報と、選手権における競技結果の記録の将来にわたる保存、並びに将来において、必要に応じ、当協会が相当と認める者に対する上記公表事項の適宜の方法による公表。
                  </td>
                </tr>
                <tr>
                  <th>
                    選手紹介
                    <br className="spOnly" />
                    情報
                  </th>
                  <td>
                    選手権の開催に際し、選手紹介情報のうちの全部又は一部を、必要に応じ、選手権関係者（報道関係者を含む）、その他、当協会が相当と認める者に対する適宜の方法による公表。
                  </td>
                </tr>
                <tr>
                  <th>
                    購入申込者
                    <br className="spOnly" />
                    情報
                  </th>
                  <td>
                    購入申込者との商品販売取引のための代金の決済、商品の発送、当該取引のための照会、その他の当該取引に関する連絡。
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="inner">
            <h2>3. 個人データの共同利用について</h2>
            <p>
              公益財団法人日本ゴルフ協会（以下「JGA」といいます）は、個人情報保護の重要性に鑑み、以下の方針に基づき個人情報の保護に努めます。
            </p>
            <h3>
              I.
              全国的各種選手権及び競技等への参加資格の審査・照会に関する共同利用
            </h3>
            <ul>
              <li>
                <strong>
                  <h4>(1) 個人情報の取得と利用目的について</h4>
                  JGAは、下記第2項に規定する個人データ（以下「個人データ」といいます）を、下記第3項に規定する各団体と共に、下記第4項に規定する利用目的（以下「利用目的」といいます）の達成に必要な範囲内で、JGA及び各団体で共同利用する場合があります。
                </strong>
              </li>
              <li>
                <h4>
                  (2)
                  JGA及び各団体の間で共同利用される個人データの項目は、次の通りです。
                </h4>
                Glid
                No.、氏名、氏名カナ、性別、生年月日、計算日、HDCPindex、種別（インデックスタイプ）、ホームクラブおよび所属倶楽部
              </li>
              <li>
                <h4>
                  (3) JGAと共同して利用する者（各団体）の範囲は、次の通りです。
                </h4>
                ①各地区ゴルフ連盟
                <ul className="list1">
                  <li>(a) 北海道ゴルフ連盟（HGA）</li>
                  <li>(b) 東北ゴルフ連盟（TGA）</li>
                  <li>(c) 関東ゴルフ連盟（KGA）</li>
                  <li>(d) 中部ゴルフ連盟（CGA）</li>
                  <li>(e) 関西ゴルフ連盟（KGU）</li>
                  <li>(f) 中国ゴルフ連盟（CGU）</li>
                  <li>(g) 四国ゴルフ連盟（SGU）</li>
                  <li>(h) 九州ゴルフ連盟（GUK）</li>
                </ul>
                <br />
                ②都道府県ゴルフ競技団体
                <ul className="list1">
                  <li>(a) 栃木県ゴルフ連盟</li>
                  <li>(b) 埼玉県ゴルフ協会</li>
                  <li>(c) 長野県ゴルフ協会</li>
                  <li>(d) 石川県ゴルフ協会</li>
                  <li>(e) 兵庫県ゴルフ連盟</li>
                  <li>(f) 高知県ゴルフ協会</li>
                </ul>
                <br />
                ③公益社団法人日本パブリックゴルフ協会（PGS）
              </li>
              <li>
                <h4>
                  (4)
                  JGA及び各団体は、次に掲げる利用目的の達成に必要な限度において、個人データを共同利用することがあります。
                </h4>
                男女を問わず、ゴルフ選手権及び対抗戦等の競技等（以下に掲げる各競技を含むが、これらに限られない。）の開催、運営に関し、参加申込者の当該ゴルフ選手権及び対抗戦等の競技等への参加資格の審査をするため
                <br />
                <br />
                <ul>
                  <div className="col22" >
                    <div className="col2">
                      <li>(a) ミッドアマチュアゴルフ選手権競技</li>
                      <li>(b) アマチュアゴルフ選手権ハンディキャップ競技</li>
                      <li>(c) アマチュアゴルフ選手権競技</li>
                      <li>(d) 小学生ゴルフ大会</li>
                      <li>(e) 小学生ゴルフ選手権競技</li>
                      <li>(f) ジュニアゴルフ選手権競技</li>
                      <li>(g) 学生ゴルフ選手権競技</li>
                      <li>(h) ミッドシニアゴルフ選手権競技</li>
                      <li>(i) シニアゴルフ選手権競技</li>
                      <li>(j) シニアオープンゴルフ選手権競技</li>
                      <li>(k) グランドシニアゴルフ選手権競技</li>
                      <li>(l) グランドシニア会ゴルフ競技</li>
                    </div>
                    <div className="col2">
                      <li>(m) 倶楽部対抗競技</li>
                      <li>(n) 日本スポーツマスターズ</li>
                      <li>(o) オープンゴルフ選手権競技</li>
                      <li>(p) 総合体育大会ゴルフ競技</li>
                      <li>(q) ハンディキャップゴルフ競技</li>
                      <li>(r) アンダーハンディキャップゴルフ競技</li>
                      <li>(s) インタークラブゴルフ競技</li>
                      <li>(t) 各杯ゴルフ選手権競技</li>
                      <li>(u) チャンピオントーナメント</li>
                      <li>(v) ジュニアゴルフ学年別チャンピオン決定戦</li>
                      <li>(w) 国民体育大会ブロック大会ゴルフ競技</li>
                    </div>
                  </div>
                </ul>
              </li>
              <li>
                <h4>(5) 共同利用の管理責任者</h4>
                （公財）日本ゴルフ協会　事務局長　佐々木　孝悦
                <br />
                東京都中央区八丁堀二丁目24番2号　八丁堀第一生命ビル4階
              </li>
            </ul>
            <h3>
              Ⅱ.
              全国各種選手権及び競技、ジュニアスクールなどイベントへの案内送付、ゴルファ
              ー保険加入に関する共同利用
            </h3>
            <ul>
              <li>
                <h4>(1) 個人情報の取得と利用目的について</h4>
                JGAは下記第２項に規定する個人データ（以下「個人データ」といいます）を下記第３項に規定する各団体とともに、下記第４項に規定する利用目的（以下「利用目的」といいます）の達成に必要な範囲で、JGA及び各団体で共同利用する場合があります。
              </li>
              <li>
                <h4>(2) JGA及び各団体の間で共同利用される個人データの項目</h4>
                会員番号、会員有効期限、氏名、氏名カナ、性別、年齢、生年月日、親権者氏名、親権者氏名カナ、親権者との関係、在籍区分、会員区分、住所、電話番号、学校名（学年）
              </li>
              <li>
                <h4>(3) JGAと共同して利用する者（各団体）の範囲</h4>
                ①各地区ゴルフ連盟
                <br />
                <ul className="indent">
                  <div className="col">
                    <div className="col2">
                      <li>(a) 北海道ゴルフ連盟（HGA）</li>
                      <li>(b) 東北ゴルフ連盟（TGA）</li>
                      <li>(c) 関東ゴルフ連盟（KGA）</li>
                      <li>(d) 中部ゴルフ連盟（CGA）</li>
                    </div>
                    <div className="col2">
                      <li>(e) 関西ゴルフ連盟（KGU）</li>
                      <li>(f) 中国ゴルフ連盟（CGU）</li>
                      <li>(g) 四国ゴルフ連盟（SGU）</li>
                      <li>(h) 九州ゴルフ連盟（GUK）</li>
                    </div>
                  </div>
                </ul>
                <br />
                ②都道府県ゴルフ競技団体
                <br />
                <ul className="indent">
                  <div className="col">
                    <div className="col2">
                      <li>(1)北海道ゴルフ連盟</li>
                      <li>(2)青森県ゴルフ連盟</li>
                      <li>(3)岩手県ゴルフ連盟</li>
                      <li>(4)宮城県ゴルフ連盟</li>
                      <li>(5)秋田県ゴルフ連盟</li>
                      <li>(6)山形県ゴルフ連盟</li>
                      <li>(7)福島県ゴルフ連盟</li>
                      <li>(8)茨城県アマチュアゴルフ連盟</li>
                      <li>(9)栃木県ゴルフ連盟</li>
                      <li>(10)群馬県ゴルフ連盟</li>
                      <li>(11)埼玉県ゴルフ協会</li>
                      <li>(12)千葉県アマチュアゴルフ協会</li>
                      <li>(13)東京都ゴルフ連盟</li>
                      <li>(14)神奈川県ゴルフ協会</li>
                      <li>(15)山梨県ゴルフ連盟</li>
                      <li>(16)新潟県ゴルフ連盟</li>
                      <li>(17)長野県ゴルフ協会</li>
                      <li>(18)富山県ゴルフ連盟</li>
                      <li>(19)石川県ゴルフ協会</li>
                      <li>(20)福井県ゴルフ協会</li>
                      <li>(21)静岡県ゴルフ連盟</li>
                      <li>(22)愛知県ゴルフ連盟</li>
                      <li>(23)三重県ゴルフ連盟</li>
                      <li>(24)岐阜県ゴルフ連盟</li>
                    </div>
                    <div className="col2">
                      <li>(25)滋賀県ゴルフ連盟</li>
                      <li>(26)京都府ゴルフ協会</li>
                      <li>(27)大阪府ゴルフ協会</li>
                      <li>(28)兵庫県ゴルフ連盟</li>
                      <li>(29)奈良県ゴルフ協会</li>
                      <li>(30)和歌山県ゴルフ連盟</li>
                      <li>(31)鳥取県ゴルフ協会</li>
                      <li>(32)島根県ゴルフ協会</li>
                      <li>(33)岡山県ゴルフ協会</li>
                      <li>(34)広島県ゴルフ協会</li>
                      <li>(35)山口県ゴルフ協会</li>
                      <li>(36)香川県ゴルフ協会</li>
                      <li>(37)徳島県ゴルフ協会</li>
                      <li>(38)愛媛県ゴルフ協会</li>
                      <li>(39)高知県ゴルフ協会</li>
                      <li>(40)福岡県ゴルフ協会</li>
                      <li>(41)佐賀県ゴルフ協会</li>
                      <li>(42)長崎県ゴルフ協会</li>
                      <li>(43)熊本県ゴルフ協会</li>
                      <li>(44)大分県ゴルフ協会</li>
                      <li>(45)宮崎県ゴルフ協会</li>
                      <li>(46)鹿児島県ゴルフ協会</li>
                      <li>(47)沖縄県ゴルフ協会</li>
                    </div>
                  </div>
                </ul>
                <br />
                <ul>
                  <div className="col list2">
                    <div className="col2">
                      <li>③一般社団法人日本女子プロゴルフ協会</li>
                      <li>④スポーツニッポン新聞社</li>
                      <li>⑤株式会社電通スポーツパートナーズ</li>
                      <li>⑥テレビ宮崎</li>
                      <li>⑦宮崎ガス株式会社</li>
                      <li>⑧株式会社大分放送</li>
                      <li>⑩NPO法人日本ジュニアゴルファー育成協議会</li>
                    </div>
                    <div className="col2">
                      <li>⑩信濃毎日新聞社</li>
                      <li>⑪公益社団法人日本プロゴルフ協会</li>
                      <li>⑫一般社団法人日本ゴルフツアー機構</li>
                      <li>⑬信越放送株式会社</li>
                      <li>⑭ケーアイ企画</li>
                      <li>⑮ダンロップスポーツエンタープライズ</li>
                      <li>⑯協和株式会社</li>
                    </div>
                  </div>
                </ul>
              </li>
              <br />
              <li>
                <h4>(4) 利用する者の利用目的</h4>
                JGA及び各団体は、次に揚げる利用目的の達成に必要な限度において、個人データを共同利用することがあります。男女問わず、ゴルフ選手権、ゴルフ大会、ジュニアスクールなどジュニア普及・育成に関するイベント（以下に揚げる各競技を含むが、これらに限られない。）の開催、運営、参加申込書の案内資料送付、ジュニア普及事業に関する情報送付のため
                <br />
                <br />
                <ul>
                  <div className="col">
                    <div className="col2">
                      <li>・地区ジュニアスクール</li>
                      <li>・小学生ゴルフ大会</li>
                      <li>・小学生ゴルフ選手権競技</li>
                      <li>・小学生ゴルフトーナメント</li>
                      <li>・ジュニアゴルフ選手権競技</li>
                      <li>・ジュニア強化指定選手選考会</li>
                      <li>・ジュニアゴルフ学年別チャンピオン決定戦</li>
                      <li>・中日レディースカップゴルフ大会</li>
                      <li>・つるやカップジュニア</li>
                      <li>・国民体育大会ブロック大会（少年の部）選考会</li>
                      <li>・高松北ロータリークラブジュニアオープン</li>
                      <li>・かがわスポーツドリーム教室</li>
                      <li>・OBS九州女子ジュニアゴルフ</li>
                    </div>
                    <div className="col2">
                      <li>・宮崎ガスジュニアゴルフ選手権</li>
                      <li>・サントリーレディスオープンジュニアレッスン会</li>
                      <li>・塚田陽亮ジュニアゴルフレッスン会</li>
                      <li>・スタジオアリス女子オープンジュニアカップ</li>
                      <li>・南日本女子ゴルフ選手権</li>
                      <li>・アサヒ緑健TVQシニアオープンジュニアイベント</li>
                      <li>・ジュニアゴルフレッスン会</li>
                      <li>・SBCジュニアゴルフ大会</li>
                      <li>・パナソニックオープンジュニアレッスン会</li>
                      <li>・パナソニックオープンワンオンチャレンジ</li>
                      <li>・石川遼ジャパンジュニア＆カレッジマスターズ</li>
                      <li>・ゴルファー保険加入手続き</li>
                    </div>
                  </div>
                </ul>
              </li>
              <br />
              <li>
                <h4>(5) 共同利用の管理責任者</h4>
                （公財）日本ゴルフ協会　事務局長　佐々木　孝悦
                <br />
                東京都中央区八丁堀二丁目24番2号　八丁堀第一生命ビル4階
              </li>
            </ul>
          </div>

          <div className="inner">
            <h2>4. 個人情報の取扱等に関する苦情および請求</h2>
            <h3>■苦情の申出及び請求の提出の受付部署</h3>
            <p>
              日本ゴルフ協会総務部・個人情報課
              <span>TEL：03-6275-2644 　FAX：03-6275-2642</span>
            </p>
            <h3>■苦情の申出方法・請求の提出方法、請求の場合の手数料額</h3>
            <p>上記受付部署に、お問い合わせ下さい。</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Privacy;
